/**
 * @generated SignedSource<<f87e2962da2f1d0bb1c779d4d3ee3a94>>
 * @relayHash 2113accbe0f2967c38f25fa5a025ce7e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 74facf5576f74b7086a7393773f3e2d5428d0cb57b3e8a1adf0efa06f929e93a

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type IssueNavigatorListViewPaginationQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  id: string;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectKey?: string | null | undefined;
  projectKeys?: ReadonlyArray<string> | null | undefined;
  shouldQueryFieldSetsById: boolean;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorListViewPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_listViewData">;
  } | null | undefined;
};
export type IssueNavigatorListViewPaginationQuery = {
  response: IssueNavigatorListViewPaginationQuery$data;
  variables: IssueNavigatorListViewPaginationQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider": isJiraListInlineEditingEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider": includeSingleSelectColorField_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJiraListInlineEditingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-inline-editing.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone1-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKeys"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v18 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v19 = {
  "kind": "Variable",
  "name": "projectKey",
  "variableName": "projectKey"
},
v20 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v21 = {
  "kind": "ScalarField",
  "name": "id"
},
v22 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  },
  {
    "kind": "Variable",
    "name": "viewConfigInput",
    "variableName": "viewConfigInput"
  }
],
v23 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v24 = {
  "kind": "ScalarField",
  "name": "key"
},
v25 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v26 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v27 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v28 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v29 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v28/*: any*/),
    (v21/*: any*/)
  ]
},
v30 = {
  "kind": "InlineFragment",
  "selections": [
    (v27/*: any*/),
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v29/*: any*/),
        (v21/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v31 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v32 = [
  {
    "kind": "Variable",
    "name": "context",
    "variableName": "fieldSetsContext"
  },
  {
    "kind": "Variable",
    "name": "filterId",
    "variableName": "filterId"
  },
  (v31/*: any*/),
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v33 = {
  "kind": "ScalarField",
  "name": "text"
},
v34 = {
  "kind": "ScalarField",
  "name": "name"
},
v35 = {
  "kind": "ScalarField",
  "name": "picture"
},
v36 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v37 = {
  "kind": "ScalarField",
  "name": "type"
},
v38 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v39 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v40 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v34/*: any*/),
                (v21/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v41 = {
  "kind": "ScalarField",
  "name": "message"
},
v42 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "concreteType": "JiraCmdbMediaClientConfig",
                          "kind": "LinkedField",
                          "name": "mediaClientConfig",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "clientId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "fileId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaBaseUrl"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaJwtToken"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v41/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v43 = [
  (v34/*: any*/),
  (v21/*: any*/)
],
v44 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v43/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v45 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v46 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v47 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    },
    (v34/*: any*/),
    (v45/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v48 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        (v34/*: any*/),
        (v46/*: any*/)
      ]
    }
  ],
  "type": "JiraDateTimePickerField"
},
v49 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v24/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v21/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v50 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v51 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v34/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v29/*: any*/),
        (v21/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v52 = [
  (v34/*: any*/)
],
v53 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v54 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v52/*: any*/)
            }
          ]
        },
        (v53/*: any*/)
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    (v34/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v55 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v21/*: any*/)
  ]
},
v56 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v55/*: any*/),
    (v21/*: any*/)
  ]
},
v57 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v58 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v57/*: any*/),
        (v34/*: any*/),
        (v21/*: any*/)
      ]
    },
    (v21/*: any*/)
  ]
},
v59 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v24/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v33/*: any*/),
            (v21/*: any*/)
          ]
        },
        (v21/*: any*/)
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v56/*: any*/),
            (v58/*: any*/)
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v56/*: any*/),
            (v58/*: any*/),
            (v25/*: any*/),
            (v59/*: any*/),
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "ids",
                  "value": [
                    "summary",
                    "issuetype",
                    "status"
                  ]
                }
              ],
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fieldsById",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v20/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v27/*: any*/),
                            (v33/*: any*/)
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v27/*: any*/),
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "issueTypeId"
                                },
                                (v34/*: any*/),
                                (v57/*: any*/),
                                (v26/*: any*/),
                                (v21/*: any*/)
                              ]
                            }
                          ],
                          "type": "JiraIssueTypeField"
                        },
                        (v30/*: any*/),
                        (v21/*: any*/)
                      ]
                    }
                  ]
                }
              ],
              "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
            }
          ]
        },
        (v34/*: any*/),
        (v46/*: any*/)
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v61 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v34/*: any*/),
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        },
        (v21/*: any*/)
      ]
    },
    (v34/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v34/*: any*/),
        (v24/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v21/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v63 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v43/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v64 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v21/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                },
                (v34/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "state"
                },
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    },
    (v34/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraSprintField"
},
v65 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v66 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v67 = {
  "kind": "InlineFragment",
  "selections": [
    (v34/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v43/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v68 = [
  (v53/*: any*/)
],
v69 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraCommentSummary",
          "kind": "LinkedField",
          "name": "commentSummary",
          "plural": false,
          "selections": (v68/*: any*/)
        }
      ],
      "type": "JiraCommentSummaryField"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v34/*: any*/),
        {
          "kind": "ScalarField",
          "name": "uri"
        },
        (v46/*: any*/)
      ],
      "type": "JiraUrlField"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v34/*: any*/),
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "fieldOption",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "value"
            },
            {
              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                (v55/*: any*/)
              ]
            },
            (v21/*: any*/),
            {
              "kind": "ScalarField",
              "name": "optionId"
            }
          ]
        },
        {
          "concreteType": "JiraFieldOperation",
          "kind": "LinkedField",
          "name": "fieldOperations",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canEdit"
            }
          ]
        },
        (v46/*: any*/)
      ],
      "type": "JiraSingleSelectField"
    }
  ]
},
v70 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v71 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v34/*: any*/),
        (v57/*: any*/),
        (v21/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v72 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v73 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v72/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v68/*: any*/)
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    (v59/*: any*/),
                    (v24/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v20/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v29/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v21/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v21/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v21/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v74 = {
  "kind": "ScalarField",
  "name": "number"
},
v75 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isStoryPointField"
        },
        (v74/*: any*/),
        (v34/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v74/*: any*/),
            (v34/*: any*/),
            (v46/*: any*/)
          ]
        },
        (v46/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v76 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v37/*: any*/),
          (v38/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v27/*: any*/),
                      (v37/*: any*/),
                      (v39/*: any*/),
                      (v21/*: any*/),
                      (v40/*: any*/),
                      (v42/*: any*/),
                      (v44/*: any*/),
                      (v47/*: any*/),
                      (v48/*: any*/),
                      (v49/*: any*/),
                      (v50/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v33/*: any*/),
                          (v34/*: any*/),
                          (v46/*: any*/)
                        ],
                        "type": "JiraSingleLineTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v34/*: any*/),
                              (v28/*: any*/),
                              (v21/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      (v51/*: any*/),
                      (v54/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v36/*: any*/),
                              (v34/*: any*/),
                              (v21/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      (v60/*: any*/),
                      (v61/*: any*/),
                      (v62/*: any*/),
                      (v63/*: any*/),
                      (v64/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v20/*: any*/),
                              (v65/*: any*/),
                              (v35/*: any*/),
                              (v34/*: any*/),
                              (v21/*: any*/),
                              (v66/*: any*/)
                            ]
                          },
                          (v34/*: any*/),
                          (v45/*: any*/),
                          (v46/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      (v67/*: any*/),
                      (v69/*: any*/),
                      (v70/*: any*/),
                      (v71/*: any*/),
                      (v73/*: any*/),
                      (v75/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v77 = {
  "kind": "ScalarField",
  "name": "__id"
},
v78 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v79 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v80 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v81 = [
  (v79/*: any*/),
  (v23/*: any*/),
  (v80/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorListViewPaginationQuery",
    "selections": [
      {
        "args": (v18/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v19/*: any*/),
              {
                "kind": "Variable",
                "name": "projectKeys",
                "variableName": "projectKeys"
              }
            ],
            "kind": "FragmentSpread",
            "name": "main_issueNavigator_IssueNavigator_listViewData"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v8/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorListViewPaginationQuery",
    "selections": [
      {
        "args": (v18/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v22/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v23/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": [
                                  (v26/*: any*/),
                                  (v21/*: any*/)
                                ]
                              },
                              (v21/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "isResolved"
                          },
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "ids",
                                "value": [
                                  "status"
                                ]
                              }
                            ],
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v20/*: any*/),
                                      (v30/*: any*/),
                                      (v21/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"status\"])"
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": [
                                  {
                                    "kind": "Variable",
                                    "name": "filterByProjectKeys",
                                    "variableName": "projectKeys"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "hasChildren"
                              },
                              {
                                "args": [
                                  (v19/*: any*/)
                                ],
                                "kind": "ScalarField",
                                "name": "canHaveChildIssues"
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v32/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v20/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          (v33/*: any*/),
                                                          (v34/*: any*/)
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          (v34/*: any*/),
                                                          {
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                              (v20/*: any*/),
                                                              (v34/*: any*/),
                                                              (v35/*: any*/),
                                                              (v21/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraSingleSelectUserPickerField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          {
                                                            "concreteType": "JiraIssueType",
                                                            "kind": "LinkedField",
                                                            "name": "issueType",
                                                            "plural": false,
                                                            "selections": [
                                                              (v34/*: any*/),
                                                              (v36/*: any*/),
                                                              (v21/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraIssueTypeField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          (v29/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v21/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Variable",
                                        "name": "fieldSetIds",
                                        "variableName": "fieldSetIds"
                                      },
                                      (v31/*: any*/)
                                    ],
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsById",
                                    "plural": false,
                                    "selections": (v76/*: any*/)
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "args": (v32/*: any*/),
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsForIssueSearchView",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSetEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldSet",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v37/*: any*/),
                                              (v38/*: any*/),
                                              {
                                                "concreteType": "JiraIssueFieldConnection",
                                                "kind": "LinkedField",
                                                "name": "fields",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          (v37/*: any*/),
                                                          (v39/*: any*/),
                                                          (v40/*: any*/),
                                                          (v42/*: any*/),
                                                          (v44/*: any*/),
                                                          (v47/*: any*/),
                                                          (v48/*: any*/),
                                                          (v49/*: any*/),
                                                          (v50/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v37/*: any*/),
                                                              (v46/*: any*/)
                                                            ],
                                                            "type": "JiraSingleLineTextField"
                                                          },
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraStatusCategory",
                                                                "kind": "LinkedField",
                                                                "name": "statusCategory",
                                                                "plural": false,
                                                                "selections": (v52/*: any*/)
                                                              }
                                                            ],
                                                            "type": "JiraStatusCategoryField"
                                                          },
                                                          (v51/*: any*/),
                                                          (v54/*: any*/),
                                                          (v60/*: any*/),
                                                          (v61/*: any*/),
                                                          (v62/*: any*/),
                                                          (v63/*: any*/),
                                                          (v64/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v37/*: any*/),
                                                              {
                                                                "kind": "LinkedField",
                                                                "name": "user",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v65/*: any*/),
                                                                  (v66/*: any*/)
                                                                ]
                                                              },
                                                              (v45/*: any*/),
                                                              (v46/*: any*/)
                                                            ],
                                                            "type": "JiraSingleSelectUserPickerField"
                                                          },
                                                          (v67/*: any*/),
                                                          (v69/*: any*/),
                                                          (v70/*: any*/),
                                                          (v71/*: any*/),
                                                          (v73/*: any*/),
                                                          (v75/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isHighlightedIssueRow"
                              },
                              (v77/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v20/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": (v78/*: any*/),
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": (v76/*: any*/),
                            "storageKey": "fieldSets(first:500)"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "startCursor"
                      },
                      (v72/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "hasPreviousPage"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "firstIssuePosition"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "lastIssuePosition"
                      }
                    ]
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "maxCursors",
                        "value": 7
                      }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": (v81/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "last",
                        "plural": false,
                        "selections": (v81/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "first",
                        "plural": false,
                        "selections": [
                          (v23/*: any*/),
                          (v79/*: any*/),
                          (v80/*: any*/)
                        ]
                      }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "issueSearchError",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "messages"
                          }
                        ],
                        "type": "JiraInvalidJqlError"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v41/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "errorType"
                          }
                        ],
                        "type": "JiraInvalidSyntaxError"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isCappingIssueSearchResult"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      (v77/*: any*/)
                    ]
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "args": (v78/*: any*/),
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldConnection",
                                        "kind": "LinkedField",
                                        "name": "fields",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v27/*: any*/),
                                                  (v20/*: any*/),
                                                  (v37/*: any*/),
                                                  (v21/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldSets(first:500)"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v22/*: any*/),
                "filters": [
                  "issueSearchInput"
                ],
                "handle": "connection",
                "key": "IssueNavigatorListViewPagination__issues",
                "kind": "LinkedHandle",
                "name": "issues"
              }
            ],
            "type": "JiraListView"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "74facf5576f74b7086a7393773f3e2d5428d0cb57b3e8a1adf0efa06f929e93a",
    "metadata": {},
    "name": "IssueNavigatorListViewPaginationQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider": isJiraListInlineEditingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider": includeSingleSelectColorField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
    }
  }
};
})();

(node as any).hash = "3e9d496ffd1db10190a168ad3e30f3b6";

export default node;
