/**
 * @generated SignedSource<<7d18c17e1a7f37eecbd22fa22e441822>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueNavigatorUI_jiraView$data = {
  readonly __typename: string;
  readonly error: {
    readonly extensions: ReadonlyArray<{
      readonly statusCode: number | null | undefined;
    }> | null | undefined;
    readonly message: string | null | undefined;
  } | null | undefined;
  readonly viewSettings?: {
    readonly canEnableGrouping: boolean | null | undefined;
    readonly isGroupingEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_FullPageIssueApp_view" | "main_issueNavigator_Header_jiraViewResult" | "main_issueNavigator_IssueResults_jiraView" | "topBar_issueNavigator_jiraViewResult">;
  readonly " $fragmentType": "main_issueNavigator_IssueNavigatorUI_jiraView";
};
export type main_issueNavigator_IssueNavigatorUI_jiraView$key = {
  readonly " $data"?: main_issueNavigator_IssueNavigatorUI_jiraView$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigatorUI_jiraView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueNavigatorUI_jiraView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "QueryError",
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "extensions",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "statusCode"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "message"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_Header_jiraViewResult"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_IssueResults_jiraView"
    },
    {
      "kind": "FragmentSpread",
      "name": "topBar_issueNavigator_jiraViewResult"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_issueNavigator_FullPageIssueApp_view"
        }
      ],
      "type": "JiraIssueSearchViewMetadata",
      "abstractKey": "__isJiraIssueSearchViewMetadata"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewSettings",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "isGroupingEnabled"
            },
            {
              "kind": "ScalarField",
              "name": "canEnableGrouping"
            }
          ]
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraView",
  "abstractKey": "__isJiraView"
};

(node as any).hash = "b6cac874859fa92dc47892f94d17abfe";

export default node;
