import React, { useCallback, useLayoutEffect, useMemo, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount/OpTrigger.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_IssueResults_groupResults$key as GroupResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_groupResults.graphql';
import type { main_issueNavigator_IssueResults_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_issueResults.graphql';
import type { main_issueNavigator_IssueResults_jiraView$key as JiraViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_jiraView.graphql';
import type { main_issueNavigator_IssueResults_project$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_project.graphql';
import type { main_issueNavigator_IssueResults_viewResult$key as ViewResultFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueResults_viewResult.graphql';
import type { main_issueNavigator_ListView_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import {
	ANALYTICS_DETAIL_VIEW_SOURCE_NAME,
	ANALYTICS_LIST_VIEW_SOURCE_NAME,
	PACKAGE_NAME,
	TEAM_NAME,
	isJiraSpreadsheetView,
} from '../../common/constants.tsx';
import type { OverridableIssueTableProps } from '../../common/types.tsx';
import { ListViewSkeleton } from '../../common/ui/loading-skeleton/index.tsx';
import { withReportErrors } from '../../common/ui/with-report-errors/index.tsx';
import { exclusiveGroupResultsOrIssueResultsFragment } from '../../common/utils/index.tsx';
import { markOnce, marks } from '../../common/utils/performance-analytics.tsx';
import { useSelectedIssueStateOldActions } from '../../controllers/selected-issue-state-old/index.tsx';
import { useSelectedIssueActions } from '../../controllers/selected-issue/hooks.tsx';
import { useSelectedViewState } from '../../controllers/selected-view-state/index.tsx';
import { SortContainer } from '../../controllers/sort-field-and-direction-state/index.tsx';
import { useResolvedJql } from '../../services/active-jql/index.tsx';
import { useIssueSearchQuery } from '../../services/issue-search-query/index.tsx';
import { useIsFetching } from '../../services/issue-search/selectors.tsx';
import DetailView from './detail-view/main.tsx';
import ErrorView, { RecoverableErrorViewBoundary } from './error-view/index.tsx';
import ListView from './list-view/main.tsx';

type Props = {
	hasServerError?: boolean;
	issueResults: IssueResultsFragment | null;
	groupResults: GroupResultsFragment | null;
	viewResult: ViewResultFragment | null;
	jiraView: JiraViewFragment | null;
	project: ProjectFragment | null;
	onChangeColumnConfiguration?: () => void;
	onChangeJql?: (jql: string) => void;
	onCaughtIssueSearchError?: (error?: Error) => void;
	isFeedbackButtonDisabled?: boolean;
	issueTableProps?: OverridableIssueTableProps;
	filter: FilterFragment | null;
	isIssueHierarchySupportEnabled?: boolean;
	// @deprecated use onIssueFlatListUpdated instead. Remove when cleaning up bulk_operations_in_nin_experiment
	onIssueFlatListUpdatedOld?: (newFlatList: string[]) => void;
	onIssueFlatListUpdated?: (newFlatKeyList: string[], newFlatIdList: string[]) => void;
};

const IssueResults = ({
	hasServerError = false,
	onChangeColumnConfiguration,
	onChangeJql,
	onCaughtIssueSearchError,
	onIssueFlatListUpdatedOld,
	onIssueFlatListUpdated,
	issueResults,
	groupResults,
	viewResult,
	jiraView,
	project,
	isFeedbackButtonDisabled,
	issueTableProps,
	filter,
	isIssueHierarchySupportEnabled,
}: Props) => {
	markOnce(marks.ISSUE_RESULTS_START);

	const [{ view: selectedView }] = useSelectedViewState();
	const jql = useResolvedJql();

	const { push } = useRouterActions();

	let onSelectedRowChange;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { setSelectedIssueByIndex } = useSelectedIssueActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		onSelectedRowChange = useCallback(
			(rowIndex: number) => {
				setSelectedIssueByIndex(rowIndex, {
					shouldDebounce: false,
					shouldNotifyOnChange: false,
					isSelectedByUserInteraction: true,
				});
			},
			[setSelectedIssueByIndex],
		);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { setSelectedIssueByIndex } = useSelectedIssueStateOldActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		onSelectedRowChange = useCallback(
			(rowIndex: number) => {
				setSelectedIssueByIndex(rowIndex, false, false, true);
			},
			[setSelectedIssueByIndex],
		);
	}

	let isFetching;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFetching = useIsFetching();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isFetching } = useIssueSearchQuery());
	}

	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_issueResults on JiraIssueConnection
			@argumentDefinitions(projectKeys: { type: "[String!]" }, projectKey: { type: "String" }) {
				...main_issueNavigator_ListView_issueResults
					@arguments(projectKeys: $projectKeys, projectKey: $projectKey)
				...main_issueNavigator_DetailView_issueResults
				issueSearchError {
					__typename
				}
			}
		`,
		issueResults,
	);

	const groupResultsData = useFragment<GroupResultsFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_groupResults on JiraSpreadsheetGroupConnection
			@argumentDefinitions(projectKeys: { type: "[String!]" }, projectKey: { type: "String" }) {
				...main_issueNavigator_ListView_groupResults
					@arguments(
						isPaginating: $isPaginating
						projectKeys: $projectKeys
						projectKey: $projectKey
					)
			}
		`,
		groupResults,
	);

	const viewResultData = useFragment<ViewResultFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_viewResult on JiraIssueSearchViewResult {
				... on JiraIssueSearchView {
					...main_issueNavigator_ListView_view
					...main_issueNavigator_DetailView_view
				}
				... on QueryError {
					message
					extensions {
						statusCode
					}
					__typename
				}
			}
		`,
		viewResult,
	);

	const jiraViewData = useFragment<JiraViewFragment>(
		graphql`
			fragment main_issueNavigator_IssueResults_jiraView on JiraView {
				__typename
				error {
					extensions {
						statusCode
					}
					message
				}
				... on JiraDetailedView {
					...main_issueNavigator_DetailView_view
				}
				... on JiraSpreadsheetView {
					...main_issueNavigator_ListView_view
				}
			}
		`,
		jiraView,
	);

	const projectData = useFragment(
		graphql`
			fragment main_issueNavigator_IssueResults_project on JiraProject {
				...main_issueNavigator_ListView_project
			}
		`,
		project,
	);

	const viewResultError = useMemo(() => {
		if (jiraViewData && isRefactorNinToViewSchemaEnabled()) {
			const message = jiraViewData.error?.message ?? '';
			const statusCode = jiraViewData.error?.extensions?.[0].statusCode;

			if (typeof statusCode !== 'number') {
				return;
			}

			return new FetchError(statusCode, message);
		}

		return viewResultData?.__typename === 'QueryError' &&
			viewResultData?.extensions?.[0]?.statusCode !== undefined &&
			viewResultData?.extensions?.[0]?.statusCode !== null
			? new FetchError(viewResultData?.extensions?.[0]?.statusCode, viewResultData?.message ?? '')
			: undefined;
	}, [
		jiraViewData,
		viewResultData?.__typename,
		viewResultData?.extensions,
		viewResultData?.message,
	]);

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_END);
	}, []);

	const onNavigateToIssue = useCallback((issueKey: string) => push(`/browse/${issueKey}`), [push]);

	const sourceName =
		selectedView === 'detail' ? ANALYTICS_DETAIL_VIEW_SOURCE_NAME : ANALYTICS_LIST_VIEW_SOURCE_NAME;

	let viewContent: ReactNode = null;

	const resultsFragment = exclusiveGroupResultsOrIssueResultsFragment({
		groupResults: groupResultsData,
		issueResults: issueResultsData,
	});

	if (isRefactorNinToViewSchemaEnabled()) {
		if (!hasServerError && jiraViewData && !jiraViewData.error) {
			if (
				selectedView === 'detail' &&
				jiraViewData.__typename === 'JiraDetailedView' &&
				resultsFragment?.issueResults
			) {
				viewContent = (
					<DetailView
						loading={isFetching}
						onNavigateToIssue={onNavigateToIssue}
						issueResults={resultsFragment.issueResults}
						view={jiraViewData}
						isFeedbackButtonDisabled={isFeedbackButtonDisabled}
					/>
				);
			} else if (
				selectedView !== 'detail' &&
				isJiraSpreadsheetView(jiraViewData) &&
				resultsFragment
			) {
				viewContent = (
					<ListView
						loading={isFetching}
						onSelectedRowChange={onSelectedRowChange}
						onIssueFlatListUpdatedOld={onIssueFlatListUpdatedOld}
						onIssueFlatListUpdated={onIssueFlatListUpdated}
						view={jiraViewData}
						project={projectData}
						onChangeColumnConfiguration={onChangeColumnConfiguration}
						isFeedbackButtonDisabled={isFeedbackButtonDisabled}
						issueTableProps={issueTableProps}
						isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
						filter={filter}
						{...resultsFragment}
					/>
				);
			}
		}
		if (!viewContent) {
			return isFetching ? <ListViewSkeleton /> : <ErrorView error={viewResultError} />;
		}
	} else {
		if (
			!resultsFragment?.issueResults ||
			resultsFragment.issueResults.issueSearchError?.__typename === 'JiraServerError' ||
			!viewResultData ||
			viewResultData?.__typename === 'QueryError'
		) {
			return isFetching ? <ListViewSkeleton /> : <ErrorView error={viewResultError} />;
		}

		viewContent =
			selectedView !== 'detail' ? (
				<ListView
					loading={isFetching}
					onSelectedRowChange={onSelectedRowChange}
					view={viewResultData}
					project={projectData}
					onChangeColumnConfiguration={onChangeColumnConfiguration}
					isFeedbackButtonDisabled={isFeedbackButtonDisabled}
					issueTableProps={issueTableProps}
					isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
					filter={filter}
					onIssueFlatListUpdatedOld={onIssueFlatListUpdatedOld}
					onIssueFlatListUpdated={onIssueFlatListUpdated}
					{...resultsFragment}
				/>
			) : (
				<DetailView
					loading={isFetching}
					onNavigateToIssue={onNavigateToIssue}
					issueResults={resultsFragment.issueResults}
					view={viewResultData}
					isFeedbackButtonDisabled={isFeedbackButtonDisabled}
				/>
			);
	}

	return (
		<UFOLabel name="issue-results">
			<ContextualAnalyticsData sourceName={sourceName} sourceType={SCREEN}>
				<RecoverableErrorViewBoundary
					recoveryProp={isFetching}
					onCaughtIssueSearchError={onCaughtIssueSearchError}
				>
					<SortContainer jql={jql} onChangeJql={onChangeJql}>
						{viewContent}
					</SortContainer>
					<OpTrigger
						op={() => {
							onCaughtIssueSearchError && onCaughtIssueSearchError(undefined);
						}}
					/>
				</RecoverableErrorViewBoundary>
			</ContextualAnalyticsData>
		</UFOLabel>
	);
};

export default withReportErrors<Props>(IssueResults, {
	id: 'ui.issue-results.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});
