/**
 * @generated SignedSource<<d0e51bea58f96f7b21c24258c34d62e0>>
 * @relayHash 3759b0a00447a28fce7511f0049c259c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 02b6ff2f4ecea3efa1709a6d72c249bf8addd5ec4fc100f74e9622756189a48c

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraFieldSetsMutationInput = {
  replaceFieldSetsInput?: JiraReplaceIssueSearchViewFieldSetsInput | null | undefined;
  resetToDefaultFieldSets?: boolean | null | undefined;
};
export type JiraReplaceIssueSearchViewFieldSetsInput = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  inclusive?: boolean | null | undefined;
  nodes: ReadonlyArray<string>;
};
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type main_replaceSpreadsheetViewFieldSetsMutation$variables = {
  amountOfColumns: number;
  fieldSetsInput?: JiraFieldSetsMutationInput | null | undefined;
  id: string;
  includeView: boolean;
};
export type main_replaceSpreadsheetViewFieldSetsMutation$data = {
  readonly jira: {
    readonly replaceSpreadsheetViewFieldSets: {
      readonly success: boolean;
      readonly view?: {
        readonly fieldSets: {
          readonly __id: string;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fieldSetId: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_columns">;
        } | null | undefined;
        readonly hasDefaultFieldSets: boolean | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type main_replaceSpreadsheetViewFieldSetsMutation$rawResponse = {
  readonly jira: {
    readonly replaceSpreadsheetViewFieldSets: {
      readonly success: boolean;
      readonly view: {
        readonly __typename: string;
        readonly fieldSets: {
          readonly __id?: string;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly displayName: string | null | undefined;
              readonly fieldSetId: string | null | undefined;
              readonly fieldSetPreferences: {
                readonly width: number | null | undefined;
              } | null | undefined;
              readonly fieldType: {
                readonly displayName: string | null | undefined;
              } | null | undefined;
              readonly isSortable: boolean | null | undefined;
              readonly jqlTerm: string | null | undefined;
              readonly type: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number | null | undefined;
        } | null | undefined;
        readonly hasDefaultFieldSets: boolean | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type main_replaceSpreadsheetViewFieldSetsMutation = {
  rawResponse: main_replaceSpreadsheetViewFieldSetsMutation$rawResponse;
  response: main_replaceSpreadsheetViewFieldSetsMutation$data;
  variables: main_replaceSpreadsheetViewFieldSetsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeView"
},
v4 = [
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "success"
},
v6 = {
  "kind": "ScalarField",
  "name": "hasDefaultFieldSets"
},
v7 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "fieldSetSelectedState": "SELECTED"
    }
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "amountOfColumns"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v9 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "displayName"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_replaceSpreadsheetViewFieldSetsMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraSpreadsheetViewPayload",
            "kind": "LinkedField",
            "name": "replaceSpreadsheetViewFieldSets",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "condition": "includeView",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "view",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "args": (v7/*: any*/),
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueSearchFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "ui_nativeIssueTable_NativeIssueTable_columns"
                          },
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "main_replaceSpreadsheetViewFieldSetsMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraSpreadsheetViewPayload",
            "kind": "LinkedField",
            "name": "replaceSpreadsheetViewFieldSets",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "condition": "includeView",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "view",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v6/*: any*/),
                      {
                        "args": (v7/*: any*/),
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueSearchFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "type"
                                  },
                                  (v11/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "jqlTerm"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "isSortable"
                                  },
                                  {
                                    "concreteType": "JiraFieldSetPreferences",
                                    "kind": "LinkedField",
                                    "name": "fieldSetPreferences",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "width"
                                      }
                                    ]
                                  },
                                  {
                                    "concreteType": "JiraFieldType",
                                    "kind": "LinkedField",
                                    "name": "fieldType",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v10/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "02b6ff2f4ecea3efa1709a6d72c249bf8addd5ec4fc100f74e9622756189a48c",
    "metadata": {},
    "name": "main_replaceSpreadsheetViewFieldSetsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a7c1d97e88a3beacc3da435a1deabb4f";

export default node;
