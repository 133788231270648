/**
 * @generated SignedSource<<2b5a7a4309a24508c88ee472d5480ca6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_ListView_view$data = {
  readonly __typename: string;
  readonly fieldSets: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldSetId: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_columns">;
  };
  readonly hasDefaultFieldSets: boolean | null | undefined;
  readonly id: string;
  readonly viewConfigSettings?: {
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly viewId: string;
  readonly viewSettings?: {
    readonly canEnableHierarchy: boolean | null | undefined;
    readonly groupByConfig: {
      readonly groupByField: {
        readonly fieldId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly isHierarchyEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_issueNavigator_ListView_view";
};
export type main_issueNavigator_ListView_view$key = {
  readonly " $data"?: main_issueNavigator_ListView_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_ListView_view">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "isHierarchyEnabled"
},
v1 = {
  "kind": "ScalarField",
  "name": "canEnableHierarchy"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "amountOfColumns"
    },
    {
      "kind": "RootArgument",
      "name": "staticViewInput"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_ListView_view",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "viewId"
      },
      "action": "THROW",
      "path": "viewId"
    },
    {
      "kind": "ScalarField",
      "name": "hasDefaultFieldSets"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "fieldSetSelectedState": "SELECTED"
            }
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "amountOfColumns"
          }
        ],
        "concreteType": "JiraIssueSearchFieldSetConnection",
        "kind": "LinkedField",
        "name": "fieldSets",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "totalCount"
          },
          {
            "concreteType": "JiraIssueSearchFieldSetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "JiraIssueSearchFieldSet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "fieldSetId"
                  }
                ]
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "ui_nativeIssueTable_NativeIssueTable_columns"
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "fieldSets"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "staticViewInput",
                  "variableName": "staticViewInput"
                }
              ],
              "concreteType": "JiraIssueSearchViewConfigSettings",
              "kind": "LinkedField",
              "name": "viewConfigSettings",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ]
            }
          ]
        }
      ],
      "type": "JiraIssueSearchView"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraIssueSearchViewConfigSettings",
          "kind": "LinkedField",
          "name": "viewSettings",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "concreteType": "JiraSpreadsheetGroupByConfig",
              "kind": "LinkedField",
              "name": "groupByConfig",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraField",
                  "kind": "LinkedField",
                  "name": "groupByField",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "fieldId"
                    }
                  ]
                }
              ]
            },
            (v0/*: any*/)
          ]
        }
      ],
      "type": "JiraSpreadsheetView",
      "abstractKey": "__isJiraSpreadsheetView"
    }
  ],
  "type": "JiraIssueSearchViewMetadata",
  "abstractKey": "__isJiraIssueSearchViewMetadata"
};
})();

(node as any).hash = "034a5e00d797a8c5b69bb2aff4053b6b";

export default node;
