import ReactDOM from 'react-dom';
import { createOperationDescriptor, getRequest, fetchQuery, graphql } from 'relay-runtime';
import { v4 as uuid } from 'uuid';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { actions_issueSearchTotalCountQuery } from '@atlassian/jira-relay/src/__generated__/actions_issueSearchTotalCountQuery.graphql';
import RefetchQuery, {
	type IssueNavigatorIssueSearchRefetchQuery,
	type IssueNavigatorIssueSearchRefetchQuery$variables as IssueNavigatorQueryVariables,
} from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type { Action } from '@atlassian/react-sweet-state';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import {
	DEFAULT_VIEW_ID,
	MAX_AMOUNT_OF_COLUMNS,
	MAX_ISSUES_PER_PAGE,
	NIN_GLOBAL_OPERATION_SCOPE,
	NIN_PROJECT_OPERATION_SCOPE,
} from '../../common/constants.tsx';
import {
	convertFilterIdToIssueNavigatorId,
	isFilterViewId,
	parseIssueNavigatorViewIdOrDefault,
} from '../../common/utils/index.tsx';
import { fetchIssueNavigatorIssueSearchRefetchQueryShadow } from '../issue-search-shadow-request/index.tsx';
import type { Actions, InfiniteScrollProps, Props, State } from './types.tsx';

/**
 * @deprecated TODO: cleanup with empanada_nin_concurrent_mode_fixes
 * Private action to set infinite scroll props into state whenever the container updates to avoid prop drilling.
 */
export const onContainerUpdate =
	(): Action<State, Props> =>
	(
		{ setState },
		{ onLoadNext, onLoadPrevious, isLoadingNext, isLoadingPrevious, hasNext, hasPrevious },
	) => {
		const infiniteScrollProps: InfiniteScrollProps = {
			onLoadNext,
			onLoadPrevious,
			isLoadingNext,
			isLoadingPrevious,
			hasNext,
			hasPrevious,
		};

		setState(infiniteScrollProps);
	};

/**
 * Private action to refetch issue search results when input arguments have updated.
 */
export const onSearchInputUpdate =
	(): Action<State, Props> =>
	({ dispatch }, { issueSearchInput, filterId, viewId }) => {
		const shouldLoadWithFilterViewId =
			viewId !== 'detail' && issueSearchInput.filterId && isCustomFilter(issueSearchInput.filterId);
		const isCurrentViewIdInvalid = isFilterViewId(viewId) && viewId !== `list_filter_${filterId}`;

		let variables = {};
		if (issueSearchInput.filterId && shouldLoadWithFilterViewId) {
			const issueNavigatorViewId = convertFilterIdToIssueNavigatorId(issueSearchInput.filterId);
			variables = {
				viewId: issueNavigatorViewId,
				fieldSetsInput: {
					viewInput: {
						namespace: 'ISSUE_NAVIGATOR',
						viewId: issueNavigatorViewId,
					},
				},
			};
		} else if (isCurrentViewIdInvalid) {
			variables = {
				viewId: DEFAULT_VIEW_ID,
				fieldSetsInput: {
					viewInput: {
						namespace: 'ISSUE_NAVIGATOR',
						viewId: DEFAULT_VIEW_ID,
					},
				},
			};
		}
		dispatch(actions.onRefetch(variables));
	};

/**
 * Private action to reset the uncapped total issue count.
 */
export const onResetTotalIssueCount =
	(): Action<State, Props> =>
	({ setState }) => {
		setState({
			totalIssueCount: null,
			totalIssueCountIsFetching: false,
			totalIssueCountIsError: false,
		});
	};

export const actions: Actions = {
	onChangeView:
		(viewId) =>
		({ dispatch }, { onSelectedViewMutation }) => {
			onSelectedViewMutation(viewId);
			dispatch(
				actions.onRefetch({
					viewId,
					fieldSetsInput: {
						viewInput: {
							namespace: 'ISSUE_NAVIGATOR',
							viewId,
						},
					},

					...(expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
						expVal('jsc_m2_hierarchy', 'isHierarchyEnabled', false) && {
							viewConfigInput: { viewInput: { namespace: 'ISSUE_NAVIGATOR', viewId } },
							staticViewInput: null,
						}),
				}),
			);
		},
	onRefetch:
		(variables = {}, options = {}) =>
		(
			{ dispatch, getState, setState },
			{
				cloudId,
				environment,
				issueSearchInput,
				onRefetchStart,
				onRefetchError,
				refetch,
				viewId,
				isHierarchyEnabled,
				projectContext,
				groupedByField,
			},
		) => {
			let { inFlightRequest } = getState();

			// Some consumers may fire refetch queries before in-flight query
			// has been completed (e.g. while user is toggling columns on and off in
			// the column picker). In these situations, we always want to fetch latest
			// data from the API as column configuration may have changed for the user.
			// To do that, we disable Relay's automatic de-deduping of requests.
			inFlightRequest?.unsubscribe();

			const staticViewInput = { isHierarchyEnabled, isGroupingEnabled: !!groupedByField };

			const finalVariables: IssueNavigatorQueryVariables = {
				cloudId,
				issueSearchInput,
				first: MAX_ISSUES_PER_PAGE,
				after: null,
				namespace: 'ISSUE_NAVIGATOR',
				viewId,
				options: null,
				fieldSetIds: [],
				shouldQueryFieldSetsById: false,
				fieldSetsInput: {
					viewInput: {
						namespace: 'ISSUE_NAVIGATOR',
						viewId,
					},
				},
				amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
				...(fg('jsc_nin_operation_scope_fe') && {
					scope: {
						operationScope: projectContext
							? NIN_PROJECT_OPERATION_SCOPE
							: NIN_GLOBAL_OPERATION_SCOPE,
					},
				}),
				filterId: null,
				isRefactorNinToViewSchemaEnabled: isRefactorNinToViewSchemaEnabled(),
				staticViewInput,
				viewConfigInput: { staticViewInput },
				groupBy: groupedByField,
				isPaginating: false,
				projectKeys: projectContext ? [projectContext] : null,
				projectKey: projectContext ?? null,
				...variables,
			};

			inFlightRequest = fetchQuery<IssueNavigatorIssueSearchRefetchQuery>(
				environment,
				RefetchQuery,
				finalVariables,
				{
					fetchPolicy: 'network-only',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				complete: () => {
					/*
					 * fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
					 * data will remain saved in the Relay store at any point after the request completes.
					 * We need to explicitly retain the operation to ensure it doesn't get deleted.
					 * See https://relay.dev/docs/api-reference/fetch-query/#behavior
					 */
					const operation = createOperationDescriptor(getRequest(RefetchQuery), finalVariables);
					const { disposables } = getState();
					setState({
						disposables: disposables.concat([environment.retain(operation)]),
					});

					let hasStoreRefetchCompleted = false;
					// We need to batch these updates as without it, Relay will cause multiple renders and invoke the
					// onComplete callback multiple times. This causes our success/fail analytics events to become
					// unreliable.
					// We introduced a similar fix for fetchQuery (see src/packages/platform/graphql/relay-scheduler/src/index.js)
					// and in React 18 will be able to remove this in favour of the startTransition/useTransition APIs.
					ReactDOM.unstable_batchedUpdates(() => {
						refetch(finalVariables, {
							fetchPolicy: 'store-only',
							onComplete: () => {
								// Relay will dispose the cached operation after a 5m timeout, in which case it will
								// refetch the query from the store and retrigger the onComplete callback. We need to
								// ensure this callback is invoked strictly once to prevent unexpected side effects,
								// e.g. https://jira.atlassian.com/browse/JRACLOUD-84106.
								if (hasStoreRefetchCompleted) {
									return;
								}

								hasStoreRefetchCompleted = true;

								/* A very difficult to debug race condition emerges when setState is called in the same
										update cycle as our refetch function. It appears to be related to useSyncExternalStore
										(used internally by RSS) internal state becoming out of sync which prevents it from
										correctly notifying listeners (i.e. hooks consumers) when an update is made. Scheduling
										the update at the end of the event loop is the simplest way of mitigating the bug,
										but we should revisit this once React concurrent rendering is enabled. */
								queueMicrotask(() => {
									setState({
										isFetching: false,
										isFetchingView: false,
										searchKey: uuid(),
									});
									dispatch(onResetTotalIssueCount());

									options.onComplete?.();
								});
							},
						});
					});
					if (projectContext && viewId !== 'detail') {
						if (fg('jira_list_hierarchy_shadow_requests')) {
							fetchIssueNavigatorIssueSearchRefetchQueryShadow(
								{
									...finalVariables,
									staticViewInput: { isHierarchyEnabled: true },
									viewConfigInput: { staticViewInput: { isHierarchyEnabled: true } },
								},
								false,
							);
						} else if (fg('jira_list_group_shadow_requests')) {
							const groupByFields = ['status', 'assignee', 'priority'];
							fetchIssueNavigatorIssueSearchRefetchQueryShadow(
								{
									...finalVariables,
									staticViewInput: { isHierarchyEnabled: false, isGroupingEnabled: true },
									viewConfigInput: {
										staticViewInput: { isHierarchyEnabled: false, isGroupingEnabled: true },
									},
									// Randomly select a groupBy field to test the shadow request
									groupBy: groupByFields[Math.floor(Math.random() * groupByFields.length)],
								},
								true,
							);
						}
					}
				},
				error: (error: Error) => {
					setState({
						isFetching: false,
						isFetchingView: false,
						isNetworkError: true,
					});
					options.onError?.();
					onRefetchError?.(error, parseIssueNavigatorViewIdOrDefault(finalVariables.viewId));
				},
				unsubscribe: () => {
					options.onUnsubscribe?.();
				},
			});

			onRefetchStart?.();
			setState({
				inFlightRequest,
				isFetching: true,
				isFetchingView: viewId !== finalVariables.viewId,
				isNetworkError: false,
			});
		},
	onRefresh:
		() =>
		({ setState, dispatch }) => {
			setState({ isRefreshing: true });
			const onFinish = () => {
				setState({ isRefreshing: false });
			};
			dispatch(
				actions.onRefetch(
					{},
					{
						onComplete: onFinish,
						onError: onFinish,
						onUnsubscribe: onFinish,
					},
				),
			);
		},
	onFetchTotalIssueCount:
		() =>
		({ setState }, { environment, cloudId, issueSearchInput, isHierarchyEnabled }) => {
			setState({
				totalIssueCount: null,
				totalIssueCountIsFetching: true,
				totalIssueCountIsError: false,
			});
			const viewConfigInput =
				expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
				expVal('jsc_m2_hierarchy', 'isHierarchyEnabled', false)
					? { staticViewInput: { isHierarchyEnabled } }
					: null;

			fetchQuery<actions_issueSearchTotalCountQuery>(
				environment,
				graphql`
					query actions_issueSearchTotalCountQuery(
						$cloudId: ID!
						$issueSearchInput: JiraIssueSearchInput!
						$viewConfigInput: JiraIssueSearchViewConfigInput
					) {
						jira {
							issueSearchTotalCount(
								cloudId: $cloudId
								issueSearchInput: $issueSearchInput
								viewConfigInput: $viewConfigInput
							) @optIn(to: ["JiraTotalIssueCount"])
						}
					}
				`,
				{ cloudId, issueSearchInput, viewConfigInput },
				{
					fetchPolicy: 'network-only',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				next: (data) => {
					const totalIssueCount = data?.jira?.issueSearchTotalCount ?? null;
					const totalIssueCountIsError = totalIssueCount === null;

					setState({
						totalIssueCount,
						totalIssueCountIsFetching: false,
						totalIssueCountIsError,
					});
				},
				error: () => {
					setState({
						totalIssueCount: null,
						totalIssueCountIsFetching: false,
						totalIssueCountIsError: true,
					});
				},
			});
		},
};
