/**
 * @generated SignedSource<<ac86c9b1564ad112a44a1281b870a7d7>>
 * @relayHash b606c8503ba13103558346bec184b2f7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 96ec47cc2b1ac76ade56418d9c4750d062d5fb519c0b910427f4eebfda415c10

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type mainGroupByDropdownContentQuery$variables = {
  issueSearchInput?: JiraIssueSearchInput | null | undefined;
  nodeId: string;
};
export type mainGroupByDropdownContentQuery$data = {
  readonly node: {
    readonly __typename: "JiraGroupedListView";
    readonly id: string;
    readonly viewSettings: {
      readonly __id: string;
      readonly groupByConfig: {
        readonly availableGroupByFieldOptions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __id: string;
              readonly fieldId: string;
              readonly name: string;
              readonly typeKey: string | null | undefined;
            };
          } | null | undefined> | null | undefined;
        };
      };
    };
  } | {
    readonly __typename: "JiraListView";
    readonly id: string;
    readonly viewSettings: {
      readonly __id: string;
      readonly groupByConfig: {
        readonly availableGroupByFieldOptions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __id: string;
              readonly fieldId: string;
              readonly name: string;
              readonly typeKey: string | null | undefined;
            };
          } | null | undefined> | null | undefined;
        };
      };
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type mainGroupByDropdownContentQuery = {
  response: mainGroupByDropdownContentQuery$data;
  variables: mainGroupByDropdownContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nodeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "nodeId"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v8 = {
  "kind": "ScalarField",
  "name": "typeKey"
},
v9 = {
  "kind": "ScalarField",
  "name": "__id"
},
v10 = {
  "kind": "ClientExtension",
  "selections": [
    (v9/*: any*/)
  ]
},
v11 = [
  {
    "kind": "RequiredField",
    "field": (v4/*: any*/),
    "action": "THROW",
    "path": "node.id"
  },
  {
    "kind": "RequiredField",
    "field": {
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "concreteType": "JiraSpreadsheetGroupByConfig",
            "kind": "LinkedField",
            "name": "groupByConfig",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "args": (v5/*: any*/),
                  "concreteType": "JiraSpreadsheetGroupByFieldOptionConnection",
                  "kind": "LinkedField",
                  "name": "availableGroupByFieldOptions",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraSpreadsheetGroupByFieldOptionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraField",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v6/*: any*/),
                                "action": "THROW",
                                "path": "node.viewSettings.groupByConfig.availableGroupByFieldOptions.edges.node.name"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v7/*: any*/),
                                "action": "THROW",
                                "path": "node.viewSettings.groupByConfig.availableGroupByFieldOptions.edges.node.fieldId"
                              },
                              (v8/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "node.viewSettings.groupByConfig.availableGroupByFieldOptions.edges.node"
                        }
                      ]
                    }
                  ]
                },
                "action": "THROW",
                "path": "node.viewSettings.groupByConfig.availableGroupByFieldOptions"
              }
            ]
          },
          "action": "THROW",
          "path": "node.viewSettings.groupByConfig"
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v9/*: any*/),
              "action": "THROW",
              "path": "node.viewSettings.__id"
            }
          ]
        }
      ]
    },
    "action": "THROW",
    "path": "node.viewSettings"
  }
],
v12 = [
  {
    "concreteType": "JiraIssueSearchViewConfigSettings",
    "kind": "LinkedField",
    "name": "viewSettings",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraSpreadsheetGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraSpreadsheetGroupByFieldOptionConnection",
            "kind": "LinkedField",
            "name": "availableGroupByFieldOptions",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSpreadsheetGroupByFieldOptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraField",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v4/*: any*/),
                      (v10/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      (v10/*: any*/)
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "mainGroupByDropdownContentQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "JiraGroupedListView"
          },
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "JiraListView"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "mainGroupByDropdownContentQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v12/*: any*/),
            "type": "JiraGroupedListView"
          },
          {
            "kind": "InlineFragment",
            "selections": (v12/*: any*/),
            "type": "JiraListView"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "96ec47cc2b1ac76ade56418d9c4750d062d5fb519c0b910427f4eebfda415c10",
    "metadata": {},
    "name": "mainGroupByDropdownContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0171840e91c9a5f391842e5a7cfe760b";

export default node;
