/**
 * @generated SignedSource<<21ed39d6bfd3f34d6cc81386a772bf5a>>
 * @relayHash f698c67ddce1323ff12e40a1f6f456c2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6f3c03bba9954a629a003a414bbe77e7c6af339a5c91e88974ebff53d2dc6a6b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiCorePermissionsQuery } from './uiCorePermissionsQuery.graphql';

const node: PreloadableConcreteRequest<uiCorePermissionsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6f3c03bba9954a629a003a414bbe77e7c6af339a5c91e88974ebff53d2dc6a6b",
    "metadata": {},
    "name": "uiCorePermissionsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
