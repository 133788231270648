import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, useMutation } from 'react-relay';
import Button from '@atlaskit/button';
import DropdownMenu, {
	type OnOpenChangeArgs,
	DropdownItem,
	DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagService, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { ErrorFlag } from '@atlassian/jira-issue-navigator-actions-common/src/ui/error-flag/index.tsx';
import { SuccessFlag } from '@atlassian/jira-issue-navigator-actions-common/src/ui/success-flag/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { mainUpdateFilterJqlMutation } from '@atlassian/jira-relay/src/__generated__/mainUpdateFilterJqlMutation.graphql';
import { saveFilterDialogEntryPoint } from '@atlassian/jira-save-filter-dialog/src/ui/save-filter-dialog/entrypoint.tsx';
import { AsyncSaveFilterDialogContent } from '@atlassian/jira-save-filter-dialog/src/ui/save-filter-dialog/save-filter-dialog-content/async.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import ThirdPartyNudge from '@atlassian/jira-third-party-nudge/src/ui/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ModalEntryPointDropdownItemTrigger } from '@atlassian/jira-modal-entry-point-dropdown-item-trigger/src/ModalEntryPointDropdownItemTrigger.tsx';
import messages from './messages.tsx';

export type Props = {
	defaultOpen?: boolean;
	filterId?: string;
	query: string;
	onFilterSave?: (filterId: string) => void;
};

export const SUCCESS_FLAG_ID = toFlagId('issue-navigator-save-filter-success-flag');
export const ERROR_FLAG_ID = toFlagId('issue-navigator-save-filter-error-flag');

export const SaveFilterDropdown = ({
	defaultOpen = false,
	filterId,
	query,
	onFilterSave,
}: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [updateJiraCustomFilterJqlMutation] = useMutation<mainUpdateFilterJqlMutation>(graphql`
		mutation mainUpdateFilterJqlMutation($id: ID!, $jql: String!) {
			jira {
				jiraFilterMutation {
					updateJiraCustomFilterJql(input: { id: $id, jql: $jql }) {
						success
						errors {
							message
						}
						filter {
							id
							name
							jql
						}
					}
				}
			}
		}
	`);

	const { activationId, cloudId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({});
	const onSaveChanges = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'saveFilterChanges',
		);
		filterId !== undefined &&
			updateJiraCustomFilterJqlMutation({
				variables: {
					id: createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'filter',
						resourceId: filterId,
						activationId,
					}),
					jql: query,
				},
				onCompleted: ({ jira }) => {
					if (jira?.jiraFilterMutation?.updateJiraCustomFilterJql) {
						const {
							success,
							filter,
							errors: jiraErrors,
						} = jira?.jiraFilterMutation?.updateJiraCustomFilterJql;

						if (success && filter) {
							const { name } = filter;
							fireTrackAnalytics(
								createAnalyticsEvent({
									actionSubject: 'issueFilterJql',
									action: 'updated',
								}),
								'issueFilterJql updated',
							);
							showFlag({
								id: SUCCESS_FLAG_ID,
								render: (renderProps: FlagCustomRenderProps) => (
									<SuccessFlag
										id={SUCCESS_FLAG_ID}
										filterName={name}
										// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
										viewUrl={`${window.location.origin}/issues/?filter=${filterId}`}
										analyticsEvent={createAnalyticsEvent({
											actionSubject: 'button',
											action: 'clicked',
										})}
										{...renderProps}
									/>
								),
							});
						} else {
							const errors = jiraErrors ? jiraErrors.map((error) => error.message || '') : [];
							showFlag({
								id: ERROR_FLAG_ID,
								render: (renderProps: FlagCustomRenderProps) => (
									<ErrorFlag id={ERROR_FLAG_ID} errors={errors} {...renderProps} />
								),
							});
							fireErrorAnalytics({
								event: analyticsEvent,
								error: new Error(errors[0]),
								meta: {
									id: 'updateFilterJqlMutation',
									packageName: 'jiraIssueNavigatorCustomFilters',
									teamName: 'empanada',
								},
							});
						}
					}
				},
				onError: (error) => {
					showFlag({
						id: ERROR_FLAG_ID,
						render: (renderProps: FlagCustomRenderProps) => (
							<ErrorFlag id={ERROR_FLAG_ID} {...renderProps} errors={['']} />
						),
					});
					fireErrorAnalytics({
						event: analyticsEvent,
						error,
						meta: {
							id: 'updateFilterJqlMutation',
							packageName: 'jiraIssueNavigatorCustomFilters',
							teamName: 'empanada',
						},
					});
					return undefined;
				},
			});
	}, [
		activationId,
		cloudId,
		createAnalyticsEvent,
		filterId,
		query,
		showFlag,
		updateJiraCustomFilterJqlMutation,
		analyticsEvent,
	]);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(defaultOpen);
	const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = useState(false);

	const onOpenSaveFilterModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'saveFilterCopy',
		);
		setIsModalOpen(true);
	}, [createAnalyticsEvent, setIsModalOpen]);

	const onCloseSaveFilterModal = useCallback(() => {
		setIsModalOpen(false);
	}, [setIsModalOpen]);

	let modalProps;
	let entryPointProps;
	let entryPointParams;
	if (fg('nin-save-filter-dialog-entry-point')) {
		modalProps = {
			width: 'medium',
			shouldScrollInViewport: true,
			shouldReturnFocus: false,
		};

		// eslint-disable-next-line react-hooks/rules-of-hooks
		entryPointProps = useMemo(
			() => ({
				jql: query,
				filterId,
				onFilterSave,
				title: formatMessage(messages.copyFilterDialogTitle),
			}),
			[query, filterId, onFilterSave, formatMessage],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		entryPointParams = useMemo(
			() => ({
				filterAri:
					filterId !== null && filterId !== undefined
						? createAri({
								resourceOwner: 'jira',
								cloudId,
								resourceType: 'filter',
								resourceId: filterId,
								activationId,
							})
						: undefined,
			}),
			[filterId, cloudId, activationId],
		);
	}

	const onTriggerClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'toggled',
				actionSubject: 'dropdownMenu',
			}),
			'filterOptions',
		);
	}, [createAnalyticsEvent]);

	return (
		<>
			{shouldShowThirdPartyNudge && <ThirdPartyNudge />}
			<DropdownMenuWrapper>
				<DropdownMenu
					isOpen={isDropdownOpen}
					onOpenChange={(attrs: OnOpenChangeArgs) => {
						setIsDropdownOpen(attrs.isOpen);
						if (attrs.isOpen) {
							setShouldShowThirdPartyNudge(true);
							onTriggerClick();
						}
					}}
					trigger={({ triggerRef, ...props }) => (
						<Button
							{...props}
							appearance="link"
							ref={triggerRef}
							iconAfter={<ChevronDown label={formatMessage(messages.saveFilter)} />}
						>
							{formatMessage(messages.saveFilter)}
						</Button>
					)}
				>
					<DropdownItemGroup>
						<DropdownItem onClick={onSaveChanges}>
							{formatMessage(messages.saveChanges)}
						</DropdownItem>
						{fg('nin-save-filter-dialog-entry-point') ? (
							<ModalEntryPointDropdownItemTrigger
								onClick={onOpenSaveFilterModal}
								modalProps={modalProps}
								entryPoint={saveFilterDialogEntryPoint}
								entryPointProps={entryPointProps}
								entryPointParams={entryPointParams}
								interactionName="save-filter-copy"
								useInternalModal
							>
								{formatMessage(messages.saveAsACopy)}
							</ModalEntryPointDropdownItemTrigger>
						) : (
							<DropdownItem onClick={onOpenSaveFilterModal}>
								{formatMessage(messages.saveAsACopy)}
							</DropdownItem>
						)}
					</DropdownItemGroup>
				</DropdownMenu>
			</DropdownMenuWrapper>
			{!fg('nin-save-filter-dialog-entry-point') && (
				<AsyncSaveFilterDialogContent
					isOpen={isModalOpen}
					jql={query}
					filterId={filterId}
					onClose={onCloseSaveFilterModal}
					onFilterSave={onFilterSave}
					title={formatMessage(messages.copyFilterDialogTitle)}
				/>
			)}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-role='droplistContent']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: '218px !important',
	},
});
