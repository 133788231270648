/**
 * @generated SignedSource<<25d610147c70487de33613b0f327d414>>
 * @relayHash 106d92e26a9966005bd13d3e90142e86
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 86a70dcbc152c39d9eaa4b8e23a822c8f561b00e32a1c73b3ff21bc0c321a2e0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraIssueSearchOperationScope = "NIN_GLOBAL" | "NIN_GLOBAL_SHADOW_REQUEST" | "NIN_PROJECT" | "NIN_PROJECT_SHADOW_REQUEST" | "%future added value";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchScope = {
  operationScope?: JiraIssueSearchOperationScope | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type IssueNavigatorIssueSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  groupBy?: string | null | undefined;
  isPaginating: boolean;
  isRefactorNinToViewSchemaEnabled: boolean;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectKey?: string | null | undefined;
  projectKeys?: ReadonlyArray<string> | null | undefined;
  scope?: JiraIssueSearchScope | null | undefined;
  shouldQueryFieldSetsById: boolean;
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew">;
};
export type IssueNavigatorIssueSearchRefetchQuery = {
  response: IssueNavigatorIssueSearchRefetchQuery$data;
  variables: IssueNavigatorIssueSearchRefetchQuery$variables;
};
({
  "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider": isJiraListInlineEditingEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider": includeSingleSelectColorField_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider: {
    readonly get: () => boolean;
  };
  readonly isRefactorNinToViewSchemaEnabled: {
    readonly get: () => boolean;
  };
});

import isCmdbMediaAvatarEnabled_provider from '@atlassian/jira-relay-provider/src/cmdb-media-avatar-fg.relayprovider';
import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isRefactorNinToViewSchemaEnabled_provider from '@atlassian/jira-relay-provider/src/is-refactor-nin-to-view-schema-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJiraListInlineEditingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-inline-editing.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone1-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupBy"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPaginating"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRefactorNinToViewSchemaEnabled"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKeys"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "staticViewInput"
},
v21 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v22 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v23 = {
  "kind": "Variable",
  "name": "projectKey",
  "variableName": "projectKey"
},
v24 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v25 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v26 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v27 = {
  "kind": "Variable",
  "name": "fieldSetsInput",
  "variableName": "fieldSetsInput"
},
v28 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v29 = {
  "kind": "Variable",
  "name": "issueSearchInput",
  "variableName": "issueSearchInput"
},
v30 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v31 = {
  "kind": "Variable",
  "name": "options",
  "variableName": "options"
},
v32 = {
  "kind": "Variable",
  "name": "viewConfigInput",
  "variableName": "viewConfigInput"
},
v33 = [
  (v24/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  (v30/*: any*/),
  (v31/*: any*/),
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  (v32/*: any*/)
],
v34 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v35 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v36 = {
  "kind": "ScalarField",
  "name": "id"
},
v37 = {
  "kind": "ScalarField",
  "name": "key"
},
v38 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v39 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v40 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v39/*: any*/),
        (v36/*: any*/)
      ]
    },
    (v36/*: any*/)
  ]
},
v41 = {
  "kind": "ScalarField",
  "name": "isResolved"
},
v42 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v43 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v44 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v43/*: any*/),
    (v36/*: any*/)
  ]
},
v45 = {
  "kind": "InlineFragment",
  "selections": [
    (v42/*: any*/),
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v44/*: any*/),
        (v36/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v46 = {
  "args": [
    {
      "kind": "Literal",
      "name": "ids",
      "value": [
        "status"
      ]
    }
  ],
  "concreteType": "JiraIssueFieldConnection",
  "kind": "LinkedField",
  "name": "fieldsById",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueFieldEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v35/*: any*/),
            (v45/*: any*/),
            (v36/*: any*/)
          ]
        }
      ]
    }
  ],
  "storageKey": "fieldsById(ids:[\"status\"])"
},
v47 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filterByProjectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "ScalarField",
      "name": "hasChildren"
    },
    {
      "args": [
        (v23/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "canHaveChildIssues"
    }
  ]
},
v48 = {
  "kind": "Variable",
  "name": "filterId",
  "variableName": "filterId"
},
v49 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v50 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v51 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v52 = [
  {
    "kind": "Variable",
    "name": "context",
    "variableName": "fieldSetsContext"
  },
  (v48/*: any*/),
  (v49/*: any*/),
  (v50/*: any*/),
  (v51/*: any*/)
],
v53 = {
  "kind": "ScalarField",
  "name": "text"
},
v54 = {
  "kind": "ScalarField",
  "name": "name"
},
v55 = {
  "kind": "ScalarField",
  "name": "picture"
},
v56 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v57 = {
  "kind": "ScalarField",
  "name": "type"
},
v58 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v59 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v54/*: any*/),
                (v36/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v61 = {
  "kind": "ScalarField",
  "name": "message"
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "concreteType": "JiraCmdbMediaClientConfig",
                          "kind": "LinkedField",
                          "name": "mediaClientConfig",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "clientId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "fileId"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaBaseUrl"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "mediaJwtToken"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v61/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v63 = [
  (v54/*: any*/),
  (v36/*: any*/)
],
v64 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v63/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v65 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v66 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v67 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    },
    (v54/*: any*/),
    (v65/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v68 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        (v54/*: any*/),
        (v66/*: any*/)
      ]
    }
  ],
  "type": "JiraDateTimePickerField"
},
v69 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v37/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v36/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v70 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v71 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v54/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v44/*: any*/),
        (v36/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v72 = [
  (v54/*: any*/)
],
v73 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v74 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v72/*: any*/)
            }
          ]
        },
        (v73/*: any*/)
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    (v54/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v75 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v36/*: any*/)
  ]
},
v76 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v75/*: any*/),
    (v36/*: any*/)
  ]
},
v77 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v78 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v77/*: any*/),
        (v54/*: any*/),
        (v36/*: any*/)
      ]
    },
    (v36/*: any*/)
  ]
},
v79 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v80 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v37/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v53/*: any*/),
            (v36/*: any*/)
          ]
        },
        (v36/*: any*/)
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v76/*: any*/),
            (v78/*: any*/)
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v76/*: any*/),
            (v78/*: any*/),
            (v38/*: any*/),
            (v79/*: any*/),
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "ids",
                  "value": [
                    "summary",
                    "issuetype",
                    "status"
                  ]
                }
              ],
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fieldsById",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v35/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v42/*: any*/),
                            (v53/*: any*/)
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v42/*: any*/),
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "issueTypeId"
                                },
                                (v54/*: any*/),
                                (v77/*: any*/),
                                (v39/*: any*/),
                                (v36/*: any*/)
                              ]
                            }
                          ],
                          "type": "JiraIssueTypeField"
                        },
                        (v45/*: any*/),
                        (v36/*: any*/)
                      ]
                    }
                  ]
                }
              ],
              "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
            }
          ]
        },
        (v54/*: any*/),
        (v66/*: any*/)
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v81 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v82 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v54/*: any*/),
        (v81/*: any*/),
        (v36/*: any*/)
      ]
    },
    (v54/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v83 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v54/*: any*/),
        (v37/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v36/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v84 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v63/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v85 = {
  "kind": "ScalarField",
  "name": "sprintId"
},
v86 = {
  "kind": "ScalarField",
  "name": "state"
},
v87 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v36/*: any*/),
                (v85/*: any*/),
                (v54/*: any*/),
                (v86/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    },
    (v54/*: any*/),
    (v66/*: any*/)
  ],
  "type": "JiraSprintField"
},
v88 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v89 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v90 = {
  "kind": "InlineFragment",
  "selections": [
    (v54/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v63/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v91 = [
  (v73/*: any*/)
],
v92 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraCommentSummary",
          "kind": "LinkedField",
          "name": "commentSummary",
          "plural": false,
          "selections": (v91/*: any*/)
        }
      ],
      "type": "JiraCommentSummaryField"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v54/*: any*/),
        {
          "kind": "ScalarField",
          "name": "uri"
        },
        (v66/*: any*/)
      ],
      "type": "JiraUrlField"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v54/*: any*/),
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "fieldOption",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "value"
            },
            {
              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                (v75/*: any*/)
              ]
            },
            (v36/*: any*/),
            {
              "kind": "ScalarField",
              "name": "optionId"
            }
          ]
        },
        {
          "concreteType": "JiraFieldOperation",
          "kind": "LinkedField",
          "name": "fieldOperations",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canEdit"
            }
          ]
        },
        (v66/*: any*/)
      ],
      "type": "JiraSingleSelectField"
    }
  ]
},
v93 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v94 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v54/*: any*/),
        (v77/*: any*/),
        (v36/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v95 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v96 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v95/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v91/*: any*/)
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    (v79/*: any*/),
                    (v37/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v35/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v44/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v36/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v36/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v36/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v97 = {
  "kind": "ScalarField",
  "name": "number"
},
v98 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isStoryPointField"
        },
        (v97/*: any*/),
        (v54/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v97/*: any*/),
            (v54/*: any*/),
            (v66/*: any*/)
          ]
        },
        (v66/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v99 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v57/*: any*/),
          (v58/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v35/*: any*/),
                      (v42/*: any*/),
                      (v57/*: any*/),
                      (v59/*: any*/),
                      (v36/*: any*/),
                      (v60/*: any*/),
                      (v62/*: any*/),
                      (v64/*: any*/),
                      (v67/*: any*/),
                      (v68/*: any*/),
                      (v69/*: any*/),
                      (v70/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v53/*: any*/),
                          (v54/*: any*/),
                          (v66/*: any*/)
                        ],
                        "type": "JiraSingleLineTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v54/*: any*/),
                              (v43/*: any*/),
                              (v36/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      (v71/*: any*/),
                      (v74/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v56/*: any*/),
                              (v54/*: any*/),
                              (v36/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      (v80/*: any*/),
                      (v82/*: any*/),
                      (v83/*: any*/),
                      (v84/*: any*/),
                      (v87/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v35/*: any*/),
                              (v88/*: any*/),
                              (v55/*: any*/),
                              (v54/*: any*/),
                              (v36/*: any*/),
                              (v89/*: any*/)
                            ]
                          },
                          (v54/*: any*/),
                          (v65/*: any*/),
                          (v66/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      (v90/*: any*/),
                      (v92/*: any*/),
                      (v93/*: any*/),
                      (v94/*: any*/),
                      (v96/*: any*/),
                      (v98/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v100 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetIds",
          "variableName": "fieldSetIds"
        },
        (v49/*: any*/)
      ],
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsById",
      "plural": false,
      "selections": (v99/*: any*/)
    }
  ]
},
v101 = {
  "kind": "ScalarField",
  "name": "__id"
},
v102 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isHighlightedIssueRow"
    },
    (v101/*: any*/)
  ]
},
v103 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v104 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": (v103/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSets",
      "plural": false,
      "selections": (v99/*: any*/),
      "storageKey": "fieldSets(first:500)"
    }
  ]
},
v105 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v106 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v105/*: any*/),
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    (v95/*: any*/),
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
},
v107 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v108 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v109 = [
  (v107/*: any*/),
  (v34/*: any*/),
  (v108/*: any*/)
],
v110 = {
  "kind": "ScalarField",
  "name": "totalIssueSearchResultCount"
},
v111 = {
  "kind": "ScalarField",
  "name": "jql"
},
v112 = {
  "kind": "ClientExtension",
  "selections": [
    (v101/*: any*/)
  ]
},
v113 = [
  {
    "concreteType": "JiraIssueEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v34/*: any*/),
      {
        "concreteType": "JiraIssue",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v52/*: any*/),
                "concreteType": "JiraIssueFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsForIssueSearchView",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v35/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v42/*: any*/),
                                          (v53/*: any*/),
                                          (v54/*: any*/)
                                        ],
                                        "type": "JiraSingleLineTextField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v42/*: any*/),
                                          (v54/*: any*/),
                                          {
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              (v35/*: any*/),
                                              (v54/*: any*/),
                                              (v55/*: any*/),
                                              (v36/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraSingleSelectUserPickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v42/*: any*/),
                                          {
                                            "concreteType": "JiraIssueType",
                                            "kind": "LinkedField",
                                            "name": "issueType",
                                            "plural": false,
                                            "selections": [
                                              (v54/*: any*/),
                                              (v56/*: any*/),
                                              (v36/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraIssueTypeField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v42/*: any*/),
                                          (v44/*: any*/)
                                        ],
                                        "type": "JiraStatusCategoryField"
                                      },
                                      (v36/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v100/*: any*/),
              {
                "condition": "shouldQueryFieldSetsById",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v52/*: any*/),
                    "concreteType": "JiraIssueFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSetsForIssueSearchView",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldSetEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v57/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraIssueFieldConnection",
                                "kind": "LinkedField",
                                "name": "fields",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v42/*: any*/),
                                          (v57/*: any*/),
                                          (v59/*: any*/),
                                          (v60/*: any*/),
                                          (v62/*: any*/),
                                          (v64/*: any*/),
                                          (v67/*: any*/),
                                          (v68/*: any*/),
                                          (v69/*: any*/),
                                          (v70/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v57/*: any*/),
                                              (v66/*: any*/)
                                            ],
                                            "type": "JiraSingleLineTextField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraStatusCategory",
                                                "kind": "LinkedField",
                                                "name": "statusCategory",
                                                "plural": false,
                                                "selections": (v72/*: any*/)
                                              }
                                            ],
                                            "type": "JiraStatusCategoryField"
                                          },
                                          (v71/*: any*/),
                                          (v74/*: any*/),
                                          (v80/*: any*/),
                                          (v82/*: any*/),
                                          (v83/*: any*/),
                                          (v84/*: any*/),
                                          (v87/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v57/*: any*/),
                                              {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v88/*: any*/),
                                                  (v89/*: any*/)
                                                ]
                                              },
                                              (v65/*: any*/),
                                              (v66/*: any*/)
                                            ],
                                            "type": "JiraSingleSelectUserPickerField"
                                          },
                                          (v90/*: any*/),
                                          (v92/*: any*/),
                                          (v93/*: any*/),
                                          (v94/*: any*/),
                                          (v96/*: any*/),
                                          (v98/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v102/*: any*/)
        ]
      },
      (v35/*: any*/),
      (v104/*: any*/)
    ]
  },
  (v106/*: any*/),
  {
    "concreteType": "JiraIssueNavigatorPageInfo",
    "kind": "LinkedField",
    "name": "issueNavigatorPageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "firstIssuePosition"
      },
      {
        "kind": "ScalarField",
        "name": "lastIssuePosition"
      }
    ]
  },
  {
    "args": [
      {
        "kind": "Literal",
        "name": "maxCursors",
        "value": 7
      }
    ],
    "concreteType": "JiraPageCursors",
    "kind": "LinkedField",
    "name": "pageCursors",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraPageCursor",
        "kind": "LinkedField",
        "name": "around",
        "plural": true,
        "selections": (v109/*: any*/)
      },
      {
        "concreteType": "JiraPageCursor",
        "kind": "LinkedField",
        "name": "last",
        "plural": false,
        "selections": (v109/*: any*/)
      },
      {
        "concreteType": "JiraPageCursor",
        "kind": "LinkedField",
        "name": "first",
        "plural": false,
        "selections": [
          (v34/*: any*/),
          (v107/*: any*/),
          (v108/*: any*/)
        ]
      }
    ],
    "storageKey": "pageCursors(maxCursors:7)"
  },
  (v110/*: any*/),
  {
    "kind": "LinkedField",
    "name": "issueSearchError",
    "plural": false,
    "selections": [
      (v35/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "kind": "ScalarField",
            "name": "messages"
          }
        ],
        "type": "JiraInvalidJqlError"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v61/*: any*/),
          {
            "kind": "ScalarField",
            "name": "errorType"
          }
        ],
        "type": "JiraInvalidSyntaxError"
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "isCappingIssueSearchResult"
  },
  (v111/*: any*/),
  (v112/*: any*/),
  {
    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "concreteType": "JiraIssueEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "args": (v103/*: any*/),
            "concreteType": "JiraIssueFieldSetConnection",
            "kind": "LinkedField",
            "name": "fieldSets",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldSetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldSet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldConnection",
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v42/*: any*/),
                                  (v35/*: any*/),
                                  (v57/*: any*/),
                                  (v36/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            "storageKey": "fieldSets(first:500)"
          }
        ]
      }
    ]
  }
],
v114 = [
  "issueSearchInput"
],
v115 = {
  "kind": "ScalarField",
  "name": "viewId"
},
v116 = [
  {
    "kind": "Variable",
    "name": "staticViewInput",
    "variableName": "staticViewInput"
  }
],
v117 = {
  "kind": "ScalarField",
  "name": "isHierarchyEnabled"
},
v118 = {
  "kind": "ScalarField",
  "name": "hasDefaultFieldSets"
},
v119 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "fieldSetSelectedState": "SELECTED"
    }
  },
  (v49/*: any*/)
],
v120 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v121 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v122 = {
  "kind": "ScalarField",
  "name": "isSortable"
},
v123 = {
  "concreteType": "JiraFieldSetPreferences",
  "kind": "LinkedField",
  "name": "fieldSetPreferences",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "width"
    }
  ]
},
v124 = {
  "concreteType": "JiraFieldType",
  "kind": "LinkedField",
  "name": "fieldType",
  "plural": false,
  "selections": [
    (v120/*: any*/)
  ]
},
v125 = {
  "kind": "ScalarField",
  "name": "canEnableHierarchy"
},
v126 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": [
        (v125/*: any*/),
        {
          "concreteType": "JiraSpreadsheetGroupByConfig",
          "kind": "LinkedField",
          "name": "groupByConfig",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraField",
              "kind": "LinkedField",
              "name": "groupByField",
              "plural": false,
              "selections": [
                (v42/*: any*/),
                (v36/*: any*/)
              ]
            }
          ]
        },
        (v117/*: any*/)
      ]
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
},
v127 = {
  "kind": "ScalarField",
  "name": "isGroupingEnabled"
},
v128 = {
  "kind": "ScalarField",
  "name": "canEnableGrouping"
},
v129 = [
  (v117/*: any*/),
  (v125/*: any*/),
  (v127/*: any*/),
  (v128/*: any*/),
  (v112/*: any*/)
],
v130 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueSearchViewConfigSettings",
      "kind": "LinkedField",
      "name": "viewSettings",
      "plural": false,
      "selections": (v129/*: any*/)
    }
  ],
  "type": "JiraSpreadsheetView",
  "abstractKey": "__isJiraSpreadsheetView"
},
v131 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v35/*: any*/),
    {
      "kind": "ScalarField",
      "name": "statusCode"
    }
  ]
},
v132 = [
  (v36/*: any*/)
],
v133 = [
  (v131/*: any*/),
  (v61/*: any*/)
],
v134 = [
  (v24/*: any*/),
  (v25/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  (v30/*: any*/),
  (v31/*: any*/)
],
v135 = [
  (v24/*: any*/),
  (v25/*: any*/),
  (v28/*: any*/),
  {
    "kind": "Variable",
    "name": "groupBy",
    "variableName": "groupBy"
  },
  (v29/*: any*/),
  (v30/*: any*/)
],
v136 = {
  "kind": "LinkedField",
  "name": "fieldValue",
  "plural": false,
  "selections": [
    (v35/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraPriority",
          "kind": "LinkedField",
          "name": "priority",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "priorityId"
            },
            (v36/*: any*/),
            (v81/*: any*/)
          ]
        },
        (v120/*: any*/)
      ],
      "type": "JiraJqlPriorityFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v35/*: any*/),
            (v88/*: any*/),
            (v36/*: any*/),
            (v55/*: any*/)
          ]
        },
        (v120/*: any*/)
      ],
      "type": "JiraJqlUserFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": (v132/*: any*/)
        },
        (v120/*: any*/),
        (v44/*: any*/)
      ],
      "type": "JiraJqlStatusFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v97/*: any*/)
      ],
      "type": "JiraJqlNumberFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "option",
          "plural": false,
          "selections": [
            (v36/*: any*/),
            (v75/*: any*/)
          ]
        },
        (v120/*: any*/)
      ],
      "type": "JiraJqlOptionFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "sprint",
          "plural": false,
          "selections": [
            (v85/*: any*/),
            (v36/*: any*/),
            (v86/*: any*/)
          ]
        },
        (v120/*: any*/)
      ],
      "type": "JiraJqlSprintFieldValue"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraGoal",
          "kind": "LinkedField",
          "name": "goal",
          "plural": false,
          "selections": [
            (v36/*: any*/),
            {
              "kind": "ScalarField",
              "name": "status"
            },
            {
              "kind": "ScalarField",
              "name": "score"
            }
          ]
        },
        (v120/*: any*/)
      ],
      "type": "JiraJqlGoalsFieldValue"
    }
  ]
},
v137 = {
  "kind": "ScalarField",
  "name": "fieldType"
},
v138 = {
  "kind": "ScalarField",
  "name": "issueCount"
},
v139 = [
  (v27/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v140 = [
  (v24/*: any*/),
  (v25/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  (v30/*: any*/),
  (v31/*: any*/),
  (v32/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v23/*: any*/),
          {
            "kind": "Variable",
            "name": "projectKeys",
            "variableName": "projectKeys"
          }
        ],
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v33/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearch",
                "plural": false,
                "selections": (v113/*: any*/)
              },
              {
                "args": (v33/*: any*/),
                "filters": (v114/*: any*/),
                "handle": "connection",
                "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                "kind": "LinkedHandle",
                "name": "issueSearch"
              },
              {
                "args": [
                  (v26/*: any*/),
                  (v48/*: any*/),
                  (v29/*: any*/),
                  (v50/*: any*/),
                  (v51/*: any*/)
                ],
                "kind": "LinkedField",
                "name": "issueSearchViewResult",
                "plural": false,
                "selections": [
                  (v35/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraIssueSearchViewResult"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v115/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": (v116/*: any*/),
                            "concreteType": "JiraIssueSearchViewConfigSettings",
                            "kind": "LinkedField",
                            "name": "viewConfigSettings",
                            "plural": false,
                            "selections": [
                              (v117/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v36/*: any*/),
                          (v118/*: any*/),
                          {
                            "args": (v119/*: any*/),
                            "concreteType": "JiraIssueSearchFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": [
                              (v73/*: any*/),
                              {
                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueSearchFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v58/*: any*/),
                                      (v57/*: any*/),
                                      (v120/*: any*/),
                                      (v121/*: any*/),
                                      (v122/*: any*/),
                                      (v123/*: any*/),
                                      (v124/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v112/*: any*/)
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": (v116/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewConfigSettings",
                                    "plural": false,
                                    "selections": [
                                      (v125/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraIssueSearchView"
                          },
                          (v126/*: any*/),
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isJiraIssueSearchViewMetadata"
                              },
                              (v36/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v116/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewConfigSettings",
                                    "plural": false,
                                    "selections": [
                                      (v125/*: any*/),
                                      (v127/*: any*/),
                                      (v128/*: any*/),
                                      (v112/*: any*/)
                                    ]
                                  }
                                ],
                                "type": "JiraIssueSearchView"
                              },
                              (v130/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueSearchViewMetadata",
                        "abstractKey": "__isJiraIssueSearchViewMetadata"
                      }
                    ],
                    "type": "JiraIssueSearchView"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v61/*: any*/),
                      (v131/*: any*/)
                    ],
                    "type": "QueryError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v132/*: any*/),
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  (v26/*: any*/),
                  (v29/*: any*/),
                  (v50/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "viewConfigInput",
                    "variableName": "staticViewInput"
                  },
                  (v51/*: any*/)
                ],
                "kind": "LinkedField",
                "name": "jiraIssueSearchView",
                "plural": false,
                "selections": [
                  (v35/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraView"
                  },
                  {
                    "concreteType": "QueryError",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": (v133/*: any*/)
                  },
                  (v36/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v115/*: any*/),
                      {
                        "args": (v119/*: any*/),
                        "concreteType": "JiraIssueSearchFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueSearchFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueSearchFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v58/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraIssueSearchViewMetadata",
                    "abstractKey": "__isJiraIssueSearchViewMetadata"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "concreteType": "JiraIssueSearchViewConfigSettings",
                        "kind": "LinkedField",
                        "name": "viewSettings",
                        "plural": false,
                        "selections": [
                          (v127/*: any*/),
                          {
                            "concreteType": "JiraSpreadsheetGroupByConfig",
                            "kind": "LinkedField",
                            "name": "groupByConfig",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraField",
                                "kind": "LinkedField",
                                "name": "groupByField",
                                "plural": false,
                                "selections": [
                                  (v42/*: any*/),
                                  (v36/*: any*/),
                                  (v54/*: any*/)
                                ]
                              }
                            ]
                          },
                          (v117/*: any*/),
                          (v128/*: any*/)
                        ]
                      },
                      (v111/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v118/*: any*/),
                          {
                            "args": (v119/*: any*/),
                            "concreteType": "JiraIssueSearchFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": [
                              (v73/*: any*/),
                              {
                                "concreteType": "JiraIssueSearchFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueSearchFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v57/*: any*/),
                                      (v120/*: any*/),
                                      (v121/*: any*/),
                                      (v122/*: any*/),
                                      (v123/*: any*/),
                                      (v124/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v112/*: any*/)
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v116/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewConfigSettings",
                                    "plural": false,
                                    "selections": (v129/*: any*/)
                                  }
                                ],
                                "type": "JiraIssueSearchView"
                              },
                              (v130/*: any*/)
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": (v116/*: any*/),
                                    "concreteType": "JiraIssueSearchViewConfigSettings",
                                    "kind": "LinkedField",
                                    "name": "viewConfigSettings",
                                    "plural": false,
                                    "selections": [
                                      (v117/*: any*/),
                                      (v125/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraIssueSearchView"
                          },
                          (v126/*: any*/)
                        ],
                        "type": "JiraIssueSearchViewMetadata",
                        "abstractKey": "__isJiraIssueSearchViewMetadata"
                      }
                    ],
                    "type": "JiraSpreadsheetView",
                    "abstractKey": "__isJiraSpreadsheetView"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v134/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": (v113/*: any*/)
                      },
                      {
                        "args": (v134/*: any*/),
                        "filters": (v114/*: any*/),
                        "handle": "connection",
                        "key": "IssueNavigatorDetailedViewPagination__issues",
                        "kind": "LinkedHandle",
                        "name": "issues"
                      }
                    ],
                    "type": "JiraDetailedView"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v135/*: any*/),
                        "concreteType": "JiraSpreadsheetGroupConnection",
                        "kind": "LinkedField",
                        "name": "groups",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraSpreadsheetGroupEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              (v34/*: any*/),
                              {
                                "concreteType": "JiraSpreadsheetGroup",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v35/*: any*/),
                                  (v36/*: any*/),
                                  (v111/*: any*/),
                                  (v136/*: any*/),
                                  (v137/*: any*/),
                                  (v138/*: any*/)
                                ]
                              },
                              (v35/*: any*/)
                            ]
                          },
                          (v106/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "groupByField"
                          },
                          (v111/*: any*/),
                          {
                            "concreteType": "QueryError",
                            "kind": "LinkedField",
                            "name": "errors",
                            "plural": true,
                            "selections": (v133/*: any*/)
                          },
                          {
                            "condition": "isPaginating",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "concreteType": "JiraSpreadsheetGroup",
                                "kind": "LinkedField",
                                "name": "firstGroup",
                                "plural": false,
                                "selections": [
                                  (v36/*: any*/),
                                  {
                                    "args": (v139/*: any*/),
                                    "concreteType": "JiraIssueConnection",
                                    "kind": "LinkedField",
                                    "name": "issues",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v38/*: any*/),
                                              (v36/*: any*/),
                                              (v35/*: any*/),
                                              (v37/*: any*/),
                                              (v40/*: any*/),
                                              (v41/*: any*/),
                                              (v46/*: any*/),
                                              (v47/*: any*/),
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": false,
                                                "selections": [
                                                  (v100/*: any*/),
                                                  {
                                                    "condition": "shouldQueryFieldSetsById",
                                                    "kind": "Condition",
                                                    "passingValue": false,
                                                    "selections": [
                                                      {
                                                        "args": (v52/*: any*/),
                                                        "concreteType": "JiraIssueFieldSetConnection",
                                                        "kind": "LinkedField",
                                                        "name": "fieldSetsForIssueSearchView",
                                                        "plural": false,
                                                        "selections": (v99/*: any*/)
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v102/*: any*/)
                                            ]
                                          },
                                          (v35/*: any*/),
                                          (v34/*: any*/),
                                          (v104/*: any*/)
                                        ]
                                      },
                                      (v110/*: any*/),
                                      {
                                        "concreteType": "PageInfo",
                                        "kind": "LinkedField",
                                        "name": "pageInfo",
                                        "plural": false,
                                        "selections": [
                                          (v105/*: any*/),
                                          (v95/*: any*/)
                                        ]
                                      },
                                      (v112/*: any*/)
                                    ]
                                  },
                                  {
                                    "args": (v139/*: any*/),
                                    "filters": [
                                      "fieldSetsInput"
                                    ],
                                    "handle": "connection",
                                    "key": "firstGroupSection_nativeIssueTable__issues",
                                    "kind": "LinkedHandle",
                                    "name": "issues"
                                  },
                                  (v111/*: any*/),
                                  (v136/*: any*/),
                                  (v137/*: any*/),
                                  (v138/*: any*/)
                                ]
                              }
                            ]
                          },
                          (v112/*: any*/)
                        ]
                      },
                      {
                        "args": (v135/*: any*/),
                        "filters": (v114/*: any*/),
                        "handle": "connection",
                        "key": "IssueNavigatorGroupedListViewPagination__groups",
                        "kind": "LinkedHandle",
                        "name": "groups"
                      }
                    ],
                    "type": "JiraGroupedListView"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v140/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": (v113/*: any*/)
                      },
                      {
                        "args": (v140/*: any*/),
                        "filters": (v114/*: any*/),
                        "handle": "connection",
                        "key": "IssueNavigatorListViewPagination__issues",
                        "kind": "LinkedHandle",
                        "name": "issues"
                      }
                    ],
                    "type": "JiraListView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "86a70dcbc152c39d9eaa4b8e23a822c8f561b00e32a1c73b3ff21bc0c321a2e0",
    "metadata": {},
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistinlineeditingrelayprovider": isJiraListInlineEditingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrccmdbmediaavatarfgrelayprovider": isCmdbMediaAvatarEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider": includeSingleSelectColorField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider
    }
  }
};
})();

(node as any).hash = "9e1c250f393024af4c821f265cd2dda8";

export default node;
