import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

import BaseFavoriteButton from '@atlassian/jira-favourite-button/src/index.tsx';
import { FILTERS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';

type Props = {
	isFavoriteInitial: boolean;
	filterId: string;
	favoriteItemName?: string | undefined;
};

export const FavoriteButton = ({ filterId, isFavoriteInitial, favoriteItemName }: Props) => (
	<BaseFavoriteButton
		key={filterId}
		baseUrl=""
		itemType={FILTERS_ITEM_TYPE}
		itemId={filterId}
		isFavoriteInitial={isFavoriteInitial}
		favoriteItemName={favoriteItemName}
		isShownInList
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavoriteButtonHeaderWrapper = styled.div({
	font: token('font.body'),
	height: '32px',
	marginTop: token('space.0', '0'),
	marginRight: token('space.050', '4px'),
	marginBottom: token('space.0', '0'),
	marginLeft: token('space.050', '4px'),
});
