export const BulkOperationsTypes = {
	BULK_EDIT: 'BULK_EDIT',
	BULK_TRANSITION: 'BULK_TRANSITION',
} as const;

export type BulkOperationsTypes = (typeof BulkOperationsTypes)[keyof typeof BulkOperationsTypes];

export const BulkTransitionsStage = {
	LOAD_BULK_TRANSITIONS: 'LOAD_BULK_TRANSITIONS',
	START_QUICK_TRANSITIONS: 'START_QUICK_TRANSITIONS',
	START_SIDEBAR_TRANSITION: 'START_SIDEBAR_TRANSITION',
	START_TRANSITION_SCREEN: 'START_TRANSITION_SCREEN',
} as const;

export type BulkTransitionsStage = (typeof BulkTransitionsStage)[keyof typeof BulkTransitionsStage];

export const BulkOperationsDismiss = {
	// ERRORS //
	ERROR_BULK_EDIT: 'ERROR_BULK_EDIT',
	ERROR_BULK_TRANSITION: 'ERROR_BULK_TRANSITION',
	ERROR_BULK_TRANSITION_SCREEN_MOUNT: 'ERROR_BULK_TRANSITION_SCREEN_MOUNT',
	// BULK EDIT //
	EDIT_FORM_PRISTINE_CANCEL: 'EDIT_FORM_PRISTINE_CANCEL',
	EDIT_FORM_DIRTY_CANCEL: 'EDIT_FORM_DIRTY_CANCEL',
	EDIT_FORM_SUMMARY_CANCEL: 'EDIT_FORM_SUMMARY_CANCEL',
	EDIT_ATTEMPT_SUBMIT: 'EDIT_ATTEMPT_SUBMIT',
	// TRANSITION DIALOG //
	TRANSITION_DIALOG_PRISTINE_CANCEL: 'TRANSITION_DIALOG_PRISTINE_CANCEL',
	TRANSITION_DIALOG_DIRTY_CANCEL: 'TRANSITION_DIALOG_DIRTY_CANCEL',
	TRANSITION_DIALOG_CANT_CONTINUE_WARNING: 'TRANSITION_DIALOG_CANT_CONTINUE_WARNING',
	TRANSITION_DIALOG_ATTEMPT_SUBMIT: 'TRANSITION_DIALOG_ATTEMPT_SUBMIT',
	// TRANSITION FORM //
	TRANSITION_FORM_PRISTINE_CANCEL: 'TRANSITION_FORM_PRISTINE_CANCEL',
	TRANSITION_FORM_DIRTY_CANCEL: 'TRANSITION_FORM_DIRTY_CANCEL',
	TRANSITION_FORM_ATTEMPT_SUBMIT: 'TRANSITION_FORM_ATTEMPT_SUBMIT',
	// TRANSITION WITH SCREEN //
	TRANSITION_WITH_SCREEN_LEGACY_LINK: 'TRANSITION_WITH_SCREEN_LEGACY_LINK',
	TRANSITION_WITH_SCREEN_SUMMARY_CANCEL: 'TRANSITION_WITH_SCREEN_SUMMARY_CANCEL',
	TRANSITION_WITH_SCREEN_CANCEL: 'TRANSITION_WITH_SCREEN_CANCEL',
	// BULK DELETE //
	DELETE_ATTEMPT_SUBMIT: 'DELETE_ATTEMPT_SUBMIT',
	DELETE_ATTEMPT_CANCEL: 'DELETE_CANCEL',
	// OTHER //
	NAVIGATION: 'NAVIGATION',
	DESELECT_ALL_ISSUES: 'DESELECT_ALL_ISSUES',
} as const;

export type BulkOperationsDismiss =
	(typeof BulkOperationsDismiss)[keyof typeof BulkOperationsDismiss];
