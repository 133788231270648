import React, {
	type ComponentType,
	useCallback,
	useLayoutEffect,
	type ReactNode,
	useMemo,
	useRef,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
import { css, styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { PermissionErrorView } from '@atlassian/jira-issue-view-errors/src/ui/permission-error-view/index.tsx';
import { UnknownErrorView } from '@atlassian/jira-issue-view-errors/src/ui/unknown-error-view/index.tsx';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async.tsx';
import type IssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/issue-app.tsx';
import { getIssueContainerMaxWidth } from '@atlassian/jira-issue-view/src/views/issue-details/issue-layout/constants.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_IssueAppWithData_issueResults$key as IssueAppKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueAppWithData_issueResults.graphql';
import type { main_issueNavigator_IssueAppWithData_view$key as ViewKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_IssueAppWithData_view.graphql';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { SpaStatePageErrorReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { lazyAfterPaint, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useStartNinIssueAppAnalytics } from '../../../controllers/issue-app-page-segment-analytics/index.tsx';
import {
	selectionState,
	useIssueSelectionState,
	useSelectedIssueStateOldActions,
} from '../../../controllers/selected-issue-state-old/index.tsx';
import { useSelectedIssueDebouncedState } from '../../../controllers/selected-issue/facade.tsx';
import { useSelectedIssueActions } from '../../../controllers/selected-issue/hooks.tsx';
import { useIssueSearchQuery } from '../../../services/issue-search-query/index.tsx';
import { useOnDeleteIssue } from '../../../services/on-delete-issue/index.tsx';
import { useIssueByFieldsRefetch } from '../../../services/refetch-issue/index.tsx';
import { ANALYTICS_SOURCE } from '../../constants.tsx';
import { markOnce, marks } from '../../utils/performance-analytics.tsx';
import HeaderActions from './header-actions/index.tsx';
import { useScrollToActivityFeed } from './utils.tsx';

const AsyncIssueAppComponent = componentWithFG(
	'make_nins_issue_app_lazyforpaint',
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(
		() =>
			import(
				/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
			),
		{ ssr: false },
	),
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() =>
			// eslint-disable-next-line jira/import/webpack-magic-comment-entries
			import(
				/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
			),
		{ ssr: false },
	),
);

type IssueAppProps = JSX.LibraryManagedAttributes<
	typeof IssueAppType,
	ComponentProps<typeof IssueAppType>
>;

type OnIssueKeyChangeArgument = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

type IssueAppWithBoundaryProps = {
	/**
	 * Flag to adjust skeleton spacing based on whether the component is in an embedded or full page layout.
	 */
	isEmbedView: boolean;
} & IssueAppProps;

const AsyncIssueAppUsingLoadingPhases = ({ isEmbedView, ...rest }: IssueAppWithBoundaryProps) => (
	<IssueBoundary
		packageName="issue-navigator"
		isEmbedView={isEmbedView}
		render={() => <UnknownErrorView />}
	>
		<AsyncIssueAppComponent {...rest} />
	</IssueBoundary>
);

const issueCardChangeExcludedEventTypes: string[] = [
	ChangeEventTypes.FIELD_CHANGE_REQUESTED,
	ChangeEventTypes.FIELD_CHANGE_FAILED,
	ChangeEventTypes.CHILD_ISSUE_ADDED,
	ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED,
];
export type IssueAppWithDataProps = {
	/**
	 * Flag to adjust the container spacing based on whether the component is in an embedded or full page layout.
	 */
	isEmbedView: boolean;
	/**
	 * Relay fragment for issue search data.
	 */
	issueResults: IssueAppKey | null;
	view: ViewKey | null;
	/**
	 * Injected IssueApp dependency.
	 */
	IssueApp?: ComponentType<IssueAppWithBoundaryProps>;
	/**
	 * Optional react node to render in the header above the issue app.
	 */
	extraHeaderActions?: ReactNode;
};

type CompatibleActionSignatures = {
	deselectIssue: () => void;
	setSelectedIssueByKey: (issueKey: IssueKey) => void;
	exitFullPageIssueAppMode: () => void;
	onDeleteIssue: (issueKeyToDelete: string) => string;
	onIssueByFieldsRefetch: (issueKey: string) => void;
};

const useJSCM1CompatibleActions = (
	connectionId: string | undefined,
	fieldSetIds: string[],
): CompatibleActionSignatures => {
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		const {
			deselectIssue,
			exitFullPageIssueAppMode,
			setSelectedIssueByKey,
			// eslint-disable-next-line react-hooks/rules-of-hooks
		} = useSelectedIssueActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const onDeleteIssue = useOnDeleteIssue(connectionId);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const onIssueByFieldsRefetch = useIssueByFieldsRefetch(fieldSetIds);
		return {
			deselectIssue,
			exitFullPageIssueAppMode,
			setSelectedIssueByKey,
			onDeleteIssue,
			onIssueByFieldsRefetch,
		};
	}

	const { deselectIssue, exitFullPageIssueAppMode, setSelectedIssueByKey } =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSelectedIssueStateOldActions();
	const { onDeleteIssue, onIssueByFieldsRefetch } =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useIssueSearchQuery();

	return {
		deselectIssue,
		exitFullPageIssueAppMode,
		setSelectedIssueByKey,
		onDeleteIssue,
		onIssueByFieldsRefetch,
	};
};

const IssueAppWithData = ({
	IssueApp = AsyncIssueAppUsingLoadingPhases,
	extraHeaderActions,
	isEmbedView,
	issueResults,
	view,
}: IssueAppWithDataProps) => {
	markOnce(marks.ISSUE_APP_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_APP_END);
	}, []);

	const selectedIssueKey = useSelectedIssueDebouncedState();

	const issueResultsData = useFragment<IssueAppKey>(
		graphql`
			fragment main_issueNavigator_IssueAppWithData_issueResults on JiraIssueConnection {
				__id
				...headerActions_issueNavigator
			}
		`,
		issueResults,
	);

	let fieldSetIds: string[] = [];
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const viewData = useFragment<ViewKey>(
			graphql`
				fragment main_issueNavigator_IssueAppWithData_view on JiraIssueSearchViewMetadata {
					fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED }) {
						edges {
							node {
								fieldSetId
							}
						}
					}
				}
			`,
			view,
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		fieldSetIds = useMemo(
			() => viewData?.fieldSets?.edges?.map((edge) => edge?.node?.fieldSetId).filter(Boolean) ?? [],
			[viewData?.fieldSets?.edges],
		);
	}

	const { onInteractive } = useStartNinIssueAppAnalytics(selectedIssueKey);

	const issueAppMetrics = useMemo(
		() => ({
			onInteractive,
		}),
		[onInteractive],
	);

	const {
		deselectIssue,
		exitFullPageIssueAppMode,
		setSelectedIssueByKey,
		onDeleteIssue,
		onIssueByFieldsRefetch,
	} = useJSCM1CompatibleActions(issueResultsData?.__id, fieldSetIds);

	const refetchCallback = useRef(onIssueByFieldsRefetch);
	refetchCallback.current = onIssueByFieldsRefetch;

	const onIssueDeleteSuccess = useCallback(
		({ issueKey: deletedIssueKey }: { issueKey: string }) => {
			const issueKeyToSelect = onDeleteIssue(deletedIssueKey);
			if (!issueKeyToSelect.length) {
				deselectIssue();
				// Return to list view if we were in full page mode
				exitFullPageIssueAppMode();
			} else {
				setSelectedIssueByKey(toIssueKey(issueKeyToSelect));
			}
		},
		[deselectIssue, exitFullPageIssueAppMode, onDeleteIssue, setSelectedIssueByKey],
	);

	const onIssueKeyChange = useCallback(
		(issueKeys: OnIssueKeyChangeArgument) => {
			setSelectedIssueByKey(issueKeys.toIssueKey);
		},
		[setSelectedIssueByKey],
	);

	// When we're in non-embedded view (i.e. full page) we need to define a max width to ensure correct full page
	// styling within the issue app.
	const maxWidth = !isEmbedView ? getIssueContainerMaxWidth() : undefined;

	if (!expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [issueSelection] = useIssueSelectionState();
		if (issueSelection === selectionState.BLANK_ISSUE_SELECTED) {
			return (
				<IssueAppContainer isEmbedView={isEmbedView}>
					<HeaderActions
						issueResults={issueResultsData}
						extraHeaderActions={extraHeaderActions}
						isEmbedView={isEmbedView}
						maxWidth={maxWidth}
					/>
					<PermissionErrorView />
				</IssueAppContainer>
			);
		}
	}
	if (expVal('jira_list_inline_editing', 'isInlineEditingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useScrollToActivityFeed(selectedIssueKey);
	}

	if (!selectedIssueKey) {
		return <SpaStatePageErrorReady />;
	}

	return (
		<IssueAppContainer isEmbedView={isEmbedView}>
			<HeaderActions
				issueResults={issueResultsData}
				extraHeaderActions={extraHeaderActions}
				isEmbedView={isEmbedView}
				maxWidth={maxWidth}
			/>
			<IssueAppHeightAdjustment>
				<ContextualAnalyticsData sourceName="issueNavigatorEmbeddedIssueView" sourceType={SCREEN}>
					<IssueApp
						metrics={issueAppMetrics}
						key={selectedIssueKey}
						isEmbedView={isEmbedView}
						analyticsSource={ANALYTICS_SOURCE}
						issueKey={toIssueKey(selectedIssueKey)}
						issueMaxWidth={maxWidth}
						onIssueKeyChange={onIssueKeyChange}
						onChange={(event: ChangeEvent) => {
							if (event && !issueCardChangeExcludedEventTypes.includes(event.type)) {
								refetchCallback.current(String(selectedIssueKey));
							}
						}}
						onIssueDeleteSuccess={onIssueDeleteSuccess}
						shouldSetInitialFocus={false}
					/>
				</ContextualAnalyticsData>
			</IssueAppHeightAdjustment>
		</IssueAppContainer>
	);
};

export default IssueAppWithData;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueAppContainer = styled.div<{
	isEmbedView: boolean;
}>(
	{
		position: 'relative',
		flexGrow: 1,
		height: '100%',
		/* Setting the min width to 0 allows the div to grow/shrink to the size of the flex parent, instead of being based
    on the intrinsic content size. */
		minWidth: 0,
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEmbedView }) =>
		!isEmbedView
			? /* When we're not in embedded view (i.e. full page mode) we need additional padding on top of our container. */
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					paddingTop: token('space.400', '32px'),
				})
			: undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueAppHeightAdjustment = styled.div({
	height: '100%',
	/* Setting the min-height to 0 allows the div to grow/shrink to the size of the flex parent, instead of being based
    on the intrinsic content height. */
	minHeight: 0,
});
