import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/core/migration/error';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import { StyledText } from './styled.tsx';

type PageAction = 'exportIssues' | 'meatballMenu' | 'apps' | 'detailsPopup' | 'columnPicker';

type ErrorStateProps = {
	pageAction: PageAction;
	description?: string;
	onRetry: () => void | Promise<void>;
	role?: string;
};

const BOX_HEIGHT = gridSize * 18;
const ERROR_ICON_BOX_WRAPPER_HEIGHT = gridSize * 9;
const ICON_SIZE = gridSize * 9;

export const ErrorState = (props: ErrorStateProps) => {
	const { pageAction, onRetry, description, role } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const onClick = () => {
		onRetry();
		fireOperationalAnalytics(createAnalyticsEvent({}), `${pageAction}Retry clicked`);
	};
	return (
		<StyledBox height={BOX_HEIGHT} role={role}>
			<StyledBox height={ERROR_ICON_BOX_WRAPPER_HEIGHT} iconSize={ICON_SIZE}>
				<ErrorIcon color={token('color.icon')} LEGACY_primaryColor={token('color.icon')} label="" />
			</StyledBox>
			<StyledText>
				{description !== undefined ? description : formatMessage(messages.errorMessage)}
			</StyledText>
			<Button
				onClick={onClick}
				appearance="link"
				testId="issue-navigator-actions-common.ui.error.button"
			>
				{formatMessage(messages.retryMessage)}
			</Button>
		</StyledBox>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledBox = styled.div<{
	height: number;
	iconSize?: number;
}>(
	{
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: `${(props: any) => props.height}px`,
		justifyContent: 'center',
		marginTop: token('space.200', '16px'),
		marginRight: token('space.300', '24px'),
		marginBottom: token('space.200', '16px'),
		marginLeft: token('space.300', '24px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ iconSize }) =>
		iconSize
			? `svg {
                   width: ${iconSize}px;
                   height: ${iconSize}px;
               }`
			: '',
);
