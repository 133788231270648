import type { Action } from '@atlassian/react-sweet-state';
import type { SelectedIssuesState, IssueTableSelectedIssuesContainerProps } from './types.tsx';

export const initialState: SelectedIssuesState = {
	selectedIssueIds: new Set([]),
	lastSelectedRowIndex: null,
};

export const actions = {
	insertSelectedIssueIds:
		(
			selectedIssueIds: string[],
		): Action<SelectedIssuesState, IssueTableSelectedIssuesContainerProps> =>
		({ setState, getState }, { onIssueSelected }) => {
			const currentState = getState();
			onIssueSelected?.(selectedIssueIds);

			setState({
				selectedIssueIds: new Set([...currentState.selectedIssueIds, ...selectedIssueIds]),
			});
		},
	removeSelectedIssueIds:
		(
			selectedIssueIds: string[],
		): Action<SelectedIssuesState, IssueTableSelectedIssuesContainerProps> =>
		({ setState, getState }) => {
			const currentState = getState();
			const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
			selectedIssueIds.forEach((selectedIssueId) => {
				updatedSelectedIssueIds.delete(selectedIssueId);
			});

			setState({
				selectedIssueIds: updatedSelectedIssueIds,
			});
		},
	insertSelectedIssueId:
		(
			selectedIssueId: string,
		): Action<SelectedIssuesState, IssueTableSelectedIssuesContainerProps> =>
		({ setState, getState }, { onIssueSelected }) => {
			const currentState = getState();
			const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
			updatedSelectedIssueIds.add(selectedIssueId);

			onIssueSelected?.([selectedIssueId]);

			setState({
				selectedIssueIds: updatedSelectedIssueIds,
			});
		},
	removeSelectedIssueId:
		(selectedIssueId: string): Action<SelectedIssuesState> =>
		({ setState, getState }) => {
			const currentState = getState();
			const updatedSelectedIssueIds = new Set(currentState.selectedIssueIds);
			updatedSelectedIssueIds.delete(selectedIssueId);

			setState({
				selectedIssueIds: updatedSelectedIssueIds,
			});
		},
	selectedOnlyIssueId:
		(
			selectedIssueId: string,
		): Action<SelectedIssuesState, IssueTableSelectedIssuesContainerProps> =>
		({ setState }, { onIssueSelected }) => {
			onIssueSelected?.([selectedIssueId]);
			setState({ selectedIssueIds: new Set([selectedIssueId]) });
		},
	updateLastSelectedRowIndex:
		(lastSelectedRowIndex: number): Action<SelectedIssuesState> =>
		({ setState }) => {
			setState({ lastSelectedRowIndex });
		},
	reset:
		(): Action<SelectedIssuesState> =>
		({ setState }) => {
			setState(initialState);
		},
	checkIssueRow:
		(
			issueId: string,
			isChecked: boolean,
			isShiftPressed: boolean,
			flatList: string[],
		): Action<SelectedIssuesState, IssueTableSelectedIssuesContainerProps> =>
		({ dispatch, getState }) => {
			const selectedIssueIds = [issueId];
			const currentState = getState();
			// TODO: Support Shift + Click when grouping is enabled https://hello.jira.atlassian.cloud/browse/DEE-6798
			const currentCheckedIndex = flatList.findIndex((id) => id === issueId);

			if (isShiftPressed && currentState.lastSelectedRowIndex !== null) {
				const start = Math.min(currentState.lastSelectedRowIndex, currentCheckedIndex);
				const end = Math.max(currentState.lastSelectedRowIndex, currentCheckedIndex);
				selectedIssueIds.push(...flatList.slice(start, end + 1).filter((id) => id !== issueId));
			}

			dispatch(actions.updateLastSelectedRowIndex(currentCheckedIndex));

			if (isChecked) {
				dispatch(actions.insertSelectedIssueIds(selectedIssueIds));
			} else {
				dispatch(actions.removeSelectedIssueIds(selectedIssueIds));
			}
		},
} as const;
export type Actions = typeof actions;
