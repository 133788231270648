import React from 'react';
import { styled, keyframes } from '@compiled/react';
import ErrorImage from '@atlaskit/icon/glyph/error';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CardBaseStyle } from '../../../../../common/ui/styled.tsx';
import ErrorInfo from './error-info/index.tsx';
import messages from './messages.tsx';
import { errorWrapperSelector, skeletonBackgroundSelector, columnMaskSelector } from './styled.tsx';

const shimmer = keyframes({
	'0%': {
		backgroundPosition: '-300px 0',
	},
	'100%': {
		backgroundPosition: '1000px 0',
	},
});
// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled
const SkeletonBackground = styled.div<{ hasError: boolean }>({
	animationDuration: '1.2s',
	animationFillMode: 'forwards',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	animationIterationCount: (props) => (props.hasError ? 0 : 'infinite'),
	animationName: shimmer,
	animationTimingFunction: 'linear',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton.subtle', colors.N30),
	backgroundImage: `linear-gradient(
        to right,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${token('color.skeleton.subtle', colors.N30)} 10%,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${token('color.skeleton', colors.N40)} 20%,
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        ${token('color.skeleton.subtle', colors.N30)} 30%
    )`,
	backgroundRepeat: 'no-repeat',
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
});
const defaultIssueCardHeight = 97;
// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled
const SkeletonContainer = styled.div({
	height: `${defaultIssueCardHeight}px`,
	display: 'flex',
	position: 'relative',
});

type Props = {
	issueIndex: number;
	hasError?: boolean;
};

const IssueCardSkeleton = ({ hasError = false, issueIndex }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Box
			as="li"
			xcss={isVisualRefreshEnabled() ? ListItemStyle : ListItemStyleOld}
			aria-label={formatMessage(messages.issueLoadingLabel, { number: issueIndex })}
		>
			<CardContainer visualRefresh={isVisualRefreshEnabled()}>
				<SkeletonContainer>
					<SkeletonBackground
						hasError={hasError}
						data-component-selector={skeletonBackgroundSelector}
					/>
					<ColumnMask data-component-selector={columnMaskSelector}>
						<SummaryMask>
							<TextLineMask fontSize={20} lineHeight={24} intrusion={20} />
							<TextLineMask fontSize={20} lineHeight={24} intrusion={60} />
						</SummaryMask>
						<FooterMask>
							<TypeMask />
							<SeparatorMask />
							<IssueKeyMask />
							{hasError ? (
								<ErrorIconContainer>
									<ErrorImage primaryColor={token('color.icon.danger', colors.R400)} label="" />
								</ErrorIconContainer>
							) : (
								<BlankMask />
							)}
						</FooterMask>
					</ColumnMask>
					<ErrorWrapper data-component-selector={errorWrapperSelector}>
						<ErrorInfo />
					</ErrorWrapper>
				</SkeletonContainer>
			</CardContainer>
		</Box>
	);
};

export default IssueCardSkeleton;

const ListItemStyleOld = xcss({
	marginBlockStart: 'space.0',
});

const ListItemStyle = xcss({
	marginBlockStart: 'space.0',
	marginBlockEnd: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CardContainer = styled(CardBaseStyle)({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ErrorWrapper = styled.div({
	display: 'none',
	width: '100%',
	margin: 0,
	alignItems: 'center',
	marginLeft: token('space.100', '8px'),
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ErrorIconContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	flexGrow: 1,
	display: 'flex',
	justifyContent: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BlankMask = styled.div({
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	display: 'flex',
	justifyContent: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SeparatorMask = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const IssueKeyMask = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 12}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TypeMask = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TextLineMask = styled.div<{ fontSize: number; intrusion: number; lineHeight: number }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props: { fontSize: number }) => props.fontSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRight: `${(props: { intrusion: number }) => props.intrusion}px solid ${token(
		'color.border.inverse',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		colors.N0,
	)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderTop: `${(props: { lineHeight: number; fontSize: number }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		(props.lineHeight - props.fontSize) / 2}px solid ${token('color.border.inverse', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: `${(props: { lineHeight: number; fontSize: number }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		(props.lineHeight - props.fontSize) / 2}px solid ${token('color.border.inverse', colors.N0)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const FooterMask = styled.div({
	display: 'flex',
	flexDirection: 'row',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border.inverse', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTop: `${gridSize * 2}px solid ${token('color.border.inverse', colors.N0)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SummaryMask = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `${gridSize}px solid ${token('color.border.inverse', colors.N0)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ColumnMask = styled.div({
	position: 'relative',
	width: '100%',
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderLeft: `${gridSize}px solid ${token('color.border.inverse', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRight: `${gridSize}px solid ${token('color.border.inverse', colors.N0)}`,
});
