/**
 * @generated SignedSource<<062589426f3af3bf58fd6fd831e47570>>
 * @relayHash de122fab1f76e33de979655b8a8c56c8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2f9cc42e6580b3f106e3737ff40b96eca4407846844604fe1c42ce16bba8fc5d

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type actions_issueSearchTotalCountQuery$variables = {
  cloudId: string;
  issueSearchInput: JiraIssueSearchInput;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
};
export type actions_issueSearchTotalCountQuery$data = {
  readonly jira: {
    readonly issueSearchTotalCount: number | null | undefined;
  } | null | undefined;
};
export type actions_issueSearchTotalCountQuery = {
  response: actions_issueSearchTotalCountQuery$data;
  variables: actions_issueSearchTotalCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueSearchInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewConfigInput"
  }
],
v1 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "issueSearchInput",
            "variableName": "issueSearchInput"
          },
          {
            "kind": "Variable",
            "name": "viewConfigInput",
            "variableName": "viewConfigInput"
          }
        ],
        "kind": "ScalarField",
        "name": "issueSearchTotalCount"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "actions_issueSearchTotalCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "actions_issueSearchTotalCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2f9cc42e6580b3f106e3737ff40b96eca4407846844604fe1c42ce16bba8fc5d",
    "metadata": {},
    "name": "actions_issueSearchTotalCountQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "82fd57c594d13d97ce59735d9e84149d";

export default node;
