import React, { useCallback, type ForwardedRef } from 'react';
import {
	commitLocalUpdate,
	graphql,
	useFragment,
	useMutation,
	useRelayEnvironment,
} from 'react-relay';
import { Inline, Stack, Text } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ui_issueTableHierarchyToggle_HierarchyToggle$key } from '@atlassian/jira-relay/src/__generated__/ui_issueTableHierarchyToggle_HierarchyToggle.graphql';
import type { ui_issueTableHierarchyToggle_HierarchyToggleMutation } from '@atlassian/jira-relay/src/__generated__/ui_issueTableHierarchyToggle_HierarchyToggleMutation.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';

import messages from './messages.tsx';

export type HierarchyToggleProps = {
	viewResult: ui_issueTableHierarchyToggle_HierarchyToggle$key;
	forwardRef?: ForwardedRef<HTMLInputElement>;
	isGroupingSupported?: boolean;
};

export const HierarchyToggle = ({
	viewResult,
	forwardRef,
	isGroupingSupported,
}: HierarchyToggleProps) => {
	const { formatMessage } = useIntl();

	const viewResultData = useFragment<ui_issueTableHierarchyToggle_HierarchyToggle$key>(
		graphql`
			fragment ui_issueTableHierarchyToggle_HierarchyToggle on JiraIssueSearchViewMetadata
			@argumentDefinitions(staticViewInput: { type: "JiraIssueSearchStaticViewInput" }) {
				id
				viewId
				... on JiraIssueSearchView {
					viewConfigSettings(staticViewInput: $staticViewInput) {
						__id
						isHierarchyEnabled
						canEnableHierarchy
						isGroupingEnabled
						canEnableGrouping
					}
				}
				... on JiraSpreadsheetView {
					viewSettings {
						__id
						isHierarchyEnabled
						canEnableHierarchy
						isGroupingEnabled
						canEnableGrouping
					}
				}
			}
		`,
		viewResult,
	);

	const { viewConfigSettings, viewSettings, id, viewId } = viewResultData;

	const hierarchyToggleViewSettings = isRefactorNinToViewSchemaEnabled()
		? viewSettings
		: viewConfigSettings;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const toggleIssueHierarchy = useUpdateHierarchyMutation();
	const { isGroupingEnabled, canEnableGrouping, isHierarchyEnabled, canEnableHierarchy, __id } =
		hierarchyToggleViewSettings ?? {};

	const onChange = useCallback(() => {
		if (id && __id) {
			fireUIAnalytics(createAnalyticsEvent({}), 'hierarchyToggle clicked', {
				isHierarchyEnabled: !isHierarchyEnabled,
			});
			toggleIssueHierarchy(id, __id, !isHierarchyEnabled);
		}
	}, [createAnalyticsEvent, isHierarchyEnabled, toggleIssueHierarchy, id, __id]);

	const isGrouping = isGroupingSupported && isGroupingEnabled && canEnableGrouping;

	return (
		<Stack>
			<Inline spread="space-between" alignBlock="center">
				<label htmlFor={`${viewId}_hierarchy_toggle`}>
					{formatMessage(messages.showHierarchy)}
				</label>
				<Toggle
					isDisabled={!canEnableHierarchy}
					isChecked={Boolean(isHierarchyEnabled && canEnableHierarchy)}
					id={`${viewId}_hierarchy_toggle`}
					ref={forwardRef}
					onChange={onChange}
					size="large"
				/>
			</Inline>
			{!canEnableHierarchy && (
				<Text color="color.text.subtlest" size="small">
					{isGrouping
						? formatMessage(messages.hierarchyDisabledReasonGroupingAndFilters)
						: formatMessage(messages.hierarchyDisabledReasonFilters)}
				</Text>
			)}
		</Stack>
	);
};

export const useUpdateHierarchyMutation = () => {
	const isAnonymous = useIsAnonymous();
	const environment = useRelayEnvironment();

	const [commit] = useMutation<ui_issueTableHierarchyToggle_HierarchyToggleMutation>(graphql`
		mutation ui_issueTableHierarchyToggle_HierarchyToggleMutation(
			$viewId: ID!
			$isHierarchyEnabled: Boolean!
		) {
			jira {
				updateIssueSearchHierarchyPreference(
					viewId: $viewId
					isHierarchyEnabled: $isHierarchyEnabled
				) @optIn(to: "JiraUpdateIssueSearchHierarchyPreference") {
					success
				}
			}
		}
	`);

	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const updateHierarchy = useCallback(
		(
			issueSearchViewResultAri: string,
			issueSearchViewResultRelayId: string,
			isHierarchyEnabled: boolean,
		) => {
			if (isAnonymous) {
				commitLocalUpdate(environment, (store) => {
					const issueSearchViewResult = store.get(issueSearchViewResultRelayId);
					issueSearchViewResult?.setValue(isHierarchyEnabled, 'isHierarchyEnabled');
				});
				return;
			}

			commit({
				variables: {
					viewId: issueSearchViewResultAri,
					isHierarchyEnabled,
				},
				optimisticUpdater(store) {
					const issueSearchViewResult = store.get(issueSearchViewResultRelayId);
					issueSearchViewResult?.setValue(isHierarchyEnabled, 'isHierarchyEnabled');
				},
				updater(store, data) {
					if (data?.jira?.updateIssueSearchHierarchyPreference?.success) {
						const issueSearchViewResult = store.get(issueSearchViewResultRelayId);
						issueSearchViewResult?.setValue(isHierarchyEnabled, 'isHierarchyEnabled');
					}
				},
				onError: (error) => {
					fireErrorAnalytics({
						meta: {
							id: 'issue-table-hierarchy-toggle',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						error,
					});

					showFlag({
						id: 'hierarchyToggleError',
						title: formatMessage(messages.hierarchyErrorFlagTitle),
						description: formatMessage(messages.hierarchyErrorFlagDescription),
						isAutoDismiss: true,
						type: 'error',
					});
				},
			});
		},
		[commit, environment, formatMessage, isAnonymous, showFlag],
	);

	return updateHierarchy;
};
