/**
 * @generated SignedSource<<42d98c0628c86b39baf1c828f2405ad9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueNavigator_IssueResults_issueResults$data = {
  readonly issueSearchError: {
    readonly __typename: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_DetailView_issueResults" | "main_issueNavigator_ListView_issueResults">;
  readonly " $fragmentType": "main_issueNavigator_IssueResults_issueResults";
};
export type main_issueNavigator_IssueResults_issueResults$key = {
  readonly " $data"?: main_issueNavigator_IssueResults_issueResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueResults_issueResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "main_issueNavigator_IssueResults_issueResults",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_ListView_issueResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueNavigator_DetailView_issueResults"
    },
    {
      "kind": "LinkedField",
      "name": "issueSearchError",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__typename"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "3b995467cd15efbfaf6e2ad9018af6f3";

export default node;
