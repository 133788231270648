import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/uiCorePermissionsQuery$parameters';
import { expVal } from '@atlassian/jira-feature-experiments';

export const asyncBulkOpsCoreEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-bulkops-core-entrypoint" */ './src/ui'),
	),
	getPreloadProps: ({ cloudId, projectKey }: { cloudId?: string; projectKey?: string }) =>
		cloudId && projectKey && expVal('bulk_delete_experiment', 'isEnabled', false)
			? {
					queries: {
						bulkDeletePermissionQuery: {
							options: {
								fetchPolicy: 'store-and-network' as const,
							},
							parameters,
							variables: {
								cloudId,
								projectKey,
							},
						},
					},
				}
			: {},
});
