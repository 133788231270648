import React from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export type BannerType = 'info' | 'warning';
export type Props = {
	bannerMessage: React.ReactNode;
	type: BannerType;
};

export const ColumnPickerBannerMessage = ({ bannerMessage, type }: Props) => (
	<BannerContent type={type}>
		{type === 'warning' ? (
			<WarningIcon spacing="spacious" label="" color="currentColor" />
		) : (
			<InfoIcon spacing="spacious" label="" color="currentColor" />
		)}
		<BannerMessage>{bannerMessage}</BannerMessage>
	</BannerContent>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContent = styled.div<{ type?: 'info' | 'warning' }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.type === 'warning'
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.warning', colors.Y75)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.information', colors.B75),
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerMessage = styled.p({
	marginTop: 0,
	marginRight: 0,
	marginBottom: 0,
	marginLeft: token('space.100', '8px'),
	font: token('font.body.small'),
});
