import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Inline, Stack } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const HideDoneItemsToggle = () => {
	const { formatMessage } = useIntl();
	const [hideDone, setHideDone] = useState(false);
	const uniqueId = uuidv4();
	const id = `${uniqueId}_hide_done_toggle`;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onChange = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'hideDoneToggle clicked', {
			hideDone: !hideDone,
		});
		setHideDone(!hideDone);
	}, [createAnalyticsEvent, setHideDone, hideDone]);

	return (
		<Stack>
			<Inline spread="space-between" alignBlock="center">
				<label htmlFor={id}>{formatMessage(messages.showHideDoneItems)}</label>
				<Toggle size="large" id={id} onChange={onChange} isChecked={hideDone} />
			</Inline>
		</Stack>
	);
};
