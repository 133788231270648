import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { HideDoneItemsToggle as HideDoneItemsToggleWithoutErrorBoundary } from './ui/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export const HideDoneItemsToggle = () => {
	return (
		<JSErrorBoundary
			fallback="unmount"
			id="issue-table-hide-done-toggle"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<HideDoneItemsToggleWithoutErrorBoundary />
		</JSErrorBoundary>
	);
};
