import React, { useEffect, memo, forwardRef, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { main_issueNavigator_Card_fieldSets$key as FieldSetsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_fieldSets.graphql';
import type { main_issueNavigator_Card_issue$key as IssueFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_issue.graphql';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CardBaseStyle } from '../../../../../common/ui/styled.tsx';
import { Footer, byFieldId } from './footer/index.tsx';

export type Props = {
	isSelected: boolean;
	issue: IssueFragment;
	fieldSets: FieldSetsFragment | null;
	style?: React.CSSProperties;
	onClick: (event: MouseEvent) => void;
	onFocus: (() => void) | undefined;
	onBlur: (() => void) | undefined;
	onMounted?: () => void;
	onContextMenu: () => void;
};

const backgroundColorSelected = token('color.background.selected', colors.B50);
const backgroundColorHovered = token('color.background.neutral.subtle.hovered', colors.N10);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledListElement = styled.li<{ visualRefresh: boolean }>(
	{
		marginTop: '0px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh && {
			marginBottom: token('space.050'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledContainer = styled(CardBaseStyle)<{ isSelected?: boolean }>({
	// relative position added to prevent absolute positioned elements inside from escaping the card
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	position: () => 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? token('color.text.selected', colors.B400) : 'inherit'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isSelected ? backgroundColorSelected : backgroundColorHovered,
		color: 'inherit',
	},
	'&:focus': {
		color: 'inherit',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryStyledContainer = styled.div<{ visualRefresh: boolean }>({
	wordWrap: 'break-word',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginBottom: ({ visualRefresh }) =>
		visualRefresh ? token('space.100') : token('space.050', '4px'),
});

const Card = forwardRef<HTMLElement, Props>(
	(
		{
			style,
			onMounted,
			onClick,
			onFocus,
			onBlur,
			issue,
			fieldSets: fieldSetsFragment,
			isSelected,
			onContextMenu,
		}: Props,
		ref,
	) => {
		const fragmentData = useFragment<IssueFragment>(
			graphql`
				fragment main_issueNavigator_Card_issue on JiraIssue
				@argumentDefinitions(
					isJscInfiniteScrollEnabled: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider"
					}
				) {
					key @required(action: THROW)
					isResolved
					fieldSetsForIssueSearchView(
						namespace: $namespace
						viewId: $viewId
						first: $amountOfColumns
						filterId: $filterId
						context: $fieldSetsContext
					) @required(action: THROW) @skip(if: $isJscInfiniteScrollEnabled) {
						...main_issueNavigator_Card_fieldSets
					}
				}
			`,
			issue,
		);
		const fieldSets = expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
			? fieldSetsFragment
			: fragmentData.fieldSetsForIssueSearchView ?? null;
		const fieldSetsData = useFragment<FieldSetsFragment>(
			graphql`
				# eslint-disable-next-line @atlassian/relay/unused-fields -- "fields" is used in the byFiledId util.
				fragment main_issueNavigator_Card_fieldSets on JiraIssueFieldSetConnection {
					...footer_issueNavigator_FooterInternal
					edges {
						node {
							# eslint-disable-next-line @atlassian/relay/unused-fields -- "fields" is used in the byFiledId util.
							fields {
								edges {
									node {
										... on JiraSingleLineTextField {
											# eslint-disable-next-line @atlassian/relay/unused-fields -- used in the byFieldId util.
											fieldId
											name
											text
										}
									}
								}
							}
						}
					}
				}
			`,
			fieldSets,
		);

		const issueKey = fragmentData.key;

		useEffect(() => {
			onMounted && onMounted();
		}, [onMounted]);

		const fieldEdges = fieldSetsData?.edges ?? null;

		const summary = byFieldId(fieldEdges, 'summary', (summaryEdge) => summaryEdge?.node?.text);

		return (
			<StyledListElement
				data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card.list-item"
				visualRefresh={isVisualRefreshEnabled()}
			>
				<StyledContainer
					// @ts-expect-error incorrect ref type
					ref={ref ?? undefined}
					onContextMenu={onContextMenu}
					isSelected={isSelected}
					onBlur={onBlur}
					tabIndex={isSelected ? 0 : -1}
					onClick={onClick}
					onFocus={onFocus}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={/* eslint-disable-line jira/react/no-style-attribute */ style}
					data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card"
					href={`/browse/${issueKey}`}
					visualRefresh={isVisualRefreshEnabled()}
				>
					<SummaryStyledContainer
						data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card.summary"
						visualRefresh={isVisualRefreshEnabled()}
					>
						{summary}
					</SummaryStyledContainer>
					<Footer
						issueKey={toIssueKey(issueKey)}
						isResolved={fragmentData.isResolved}
						fieldSets={fieldSetsData}
						isSelected={isSelected}
					/>
				</StyledContainer>
			</StyledListElement>
		);
	},
);

export default memo(Card);
