import React, { type ComponentType, useLayoutEffect, useRef, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils/src/utils/component-with-condition/index.tsx';
import { ElementSizeProvider } from '@atlassian/jira-native-issue-table/src/controllers/element-size/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_DetailView_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_DetailView_issueResults.graphql';
import type { main_issueNavigator_DetailView_view$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_DetailView_view.graphql';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import IssueAppWithData, {
	type IssueAppWithDataProps,
} from '../../../common/ui/issue-app/main.tsx';
import { withReportErrors } from '../../../common/ui/with-report-errors/index.tsx';
import { markOnce, marks } from '../../../common/utils/performance-analytics.tsx';
import CardContainer from './card-container/index.tsx';
import CardList from './card-list/main.tsx';

type Props = {
	loading: boolean;
	onNavigateToIssue: (issueKey: string) => void;
	issueResults: IssueResultsFragment;
	view: ViewFragment;
	isFeedbackButtonDisabled?: boolean;
} & {
	IssueApp?: ComponentType<IssueAppWithDataProps>;
};

const ConditionalContentContainer = ({ children }: { children: React.ReactNode }) =>
	getWillShowNav4() ? (
		<Box xcss={ContentContainerStyles}>{children}</Box>
	) : (
		<ContentStyledContainer>{children}</ContentStyledContainer>
	);

const MaybeListElementProvider = componentWithCondition(
	() => expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false),
	ElementSizeProvider,
	({ children }: PropsWithChildren<{}>) => children,
);

const DetailView = ({
	IssueApp = IssueAppWithData,
	issueResults,
	view,
	isFeedbackButtonDisabled,
	...props
}: Props) => {
	const listRef = useRef<HTMLUListElement>(null);
	markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_START);
	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_DETAIL_VIEW_END);
	}, []);

	const detailViewData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_DetailView_issueResults on JiraIssueConnection {
				...main_issueNavigator_CardList_fragment
				...cardContainer_issueNavigator_issueResults
				...main_issueNavigator_IssueAppWithData_issueResults
			}
		`,
		issueResults,
	);

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment main_issueNavigator_DetailView_view on JiraIssueSearchViewMetadata {
				...cardContainer_issueNavigator_view
				...main_issueNavigator_IssueAppWithData_view
			}
		`,
		view,
	);

	return (
		<UFOLabel name="detail-view">
			<ConditionalContentContainer>
				<MaybeListElementProvider elementRef={listRef}>
					<CardContainer
						issueResults={detailViewData}
						view={viewData}
						isFeedbackButtonDisabled={isFeedbackButtonDisabled}
					>
						<CardList fragment={detailViewData} {...props} listRef={listRef} />
					</CardContainer>
				</MaybeListElementProvider>
				<IssueApp isEmbedView issueResults={detailViewData} view={viewData} />
				<FireScreenAnalytics />
			</ConditionalContentContainer>
		</UFOLabel>
	);
};

// re-render regression detection
DetailView.whyDidYouRender = true;

export default withReportErrors<Props>(DetailView, {
	id: 'ui.issue-results.detail-view.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStyledContainer = styled.div({
	display: 'flex',
	height: '100%',
	flexGrow: 1,
	overflow: 'hidden',
});

const ContentContainerStyles = xcss({
	display: 'flex',
	height: '100%',
	flexGrow: 1,
	overflow: 'hidden',
	flexBasis: 0,
});
