/**
 * @generated SignedSource<<e1e116b136b4001a05c8be655018fd22>>
 * @relayHash 429c30f8e9846adde759de4caf8955f1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 495ddbc0b92cf03ad75b903d1fa5f681c5513eb0eafb21c70b78f21269e4198d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcFullCoreQuery } from './srcFullCoreQuery.graphql';

const node: PreloadableConcreteRequest<srcFullCoreQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "495ddbc0b92cf03ad75b903d1fa5f681c5513eb0eafb21c70b78f21269e4198d",
    "metadata": {},
    "name": "srcFullCoreQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
