/**
 * @generated SignedSource<<e6f84906e8721209d7ac0f26b946b837>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$data = {
  readonly edges: ReadonlyArray<{
    readonly fieldSets: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fields: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly __typename: string;
                readonly fieldId: string;
                readonly type: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly node: {
      readonly issueId: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore";
};
export type bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$key = {
  readonly " $data"?: bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore$data;
  readonly " $fragmentSpreads": FragmentRefs<"bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "bulkOperationsWrapper_issueNavigator_LoadBulkOpsCore",
  "selections": [
    {
      "concreteType": "JiraIssueEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            }
          ]
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 500
            }
          ],
          "concreteType": "JiraIssueFieldSetConnection",
          "kind": "LinkedField",
          "name": "fieldSets",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSetEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldSet",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fields",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "fieldId"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "__typename"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "type"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          "storageKey": "fieldSets(first:500)"
        }
      ]
    }
  ],
  "type": "JiraIssueConnection"
};

(node as any).hash = "ba7e7ca7897cdd7e00a96824b0bb2213";

export default node;
