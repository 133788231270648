import { createActionsHook, createStateHook } from '@atlassian/react-sweet-state';
import { Store } from './index.tsx';
import type { State, Actions, InfiniteScrollProps as Props } from './types.tsx';

export const useSearchKey = createStateHook(Store, { selector: (state) => state.searchKey });

export const useIsFetching = createStateHook(Store, { selector: (state) => state.isFetching });

export const useIsFetchingView = createStateHook(Store, {
	selector: (state) => state.isFetchingView,
});

export const useIsNetworkError = createStateHook(Store, {
	selector: (state) => state.isNetworkError,
});

export const useIsRefreshing = createStateHook(Store, { selector: (state) => state.isRefreshing });

export const useTotalIssueCount = createStateHook(Store, {
	selector: (state) => state.totalIssueCount,
});

export const useTotalIssueCountIsFetching = createStateHook(Store, {
	selector: (state) => state.totalIssueCountIsFetching,
});

export const useTotalIssueCountIsError = createStateHook(Store, {
	selector: (state) => state.totalIssueCountIsError,
});

export const useIssueSearchActions = createActionsHook(Store);

/**
 * @deprecated Use `useInfiniteScroll` from `services/infinite-scroll` instead.
 * TODO: Cleanup with empanada_nin_concurrent_mode_fixes
 * Get all infinite scroll related props to avoid prop drilling.
 */
export const useInfiniteScrollProps = createStateHook<State, Actions, Props>(Store, {
	selector: ({
		onLoadNext,
		onLoadPrevious,
		isLoadingNext,
		isLoadingPrevious,
		hasNext,
		hasPrevious,
	}) => ({
		onLoadNext,
		onLoadPrevious,
		isLoadingNext,
		isLoadingPrevious,
		hasNext,
		hasPrevious,
	}),
});
